import React, { Component } from 'react'
import Axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import { Link } from 'react-router-dom'
import { CKEditor } from 'ckeditor4-react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Asideleft from '../../components/aside/asideleft'
import { Select, notification, Radio, Input, Form, Button, Tooltip,Table } from 'antd';
import {Modal as AntModal} from 'antd';
import { Modal } from "react-bootstrap";
import { v5 as uuidv5 } from 'uuid';
import MyHcSpeechRecognition from "../../pages/speech-to-text/speech-recognition";
import KeyFindingsChartNew from "../../components/chart/key-findings-chart-new";
import KeyFindingsNew from "../../components/vitals/key-findings-new";
import Moment from 'react-moment';
import IPDVitalCharting from '../patient-detail/vital-charting'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import
{
    REFERENCE_TYPE,
    PATIENT_DATA,
    LOADER_RED,
    OPD_SPECIALITY,
    HIS_FORMS,
    PATIENT_VISIT_DETAILS,
    CASE_SUMMARY_TEMPLATE,
    CASE_SUMMARY_EDITOR_TEMPLATE,
    PATIENT_CASE_SUMMARY_TEMPLATE,
    PATIENT_CASE_SUMMARY_LAST_TEMPLATE,
    CASE_SUMMARY_EDITOR_SAVE,
    CASE_SUMMARY_EDITOR_PRINT,
    CASE_SUMMARY_TIMELINE,
    Discharge_Summary_Patient_Forms,
    Discharge_Summary_Patient_Forms_Fields,
    Discharge_Summary_Patient_Graphs,
    ADMITTING_PRACTITIONER_DETAILS_PRINT,
    KEY_FINDINGS_LIS,
    EXISTING_ORDERS_LIST,
    LIS_ORDERS_RESULT
} from '../../utils/constant';
import { headerService$ } from '../../utils/rxjs-sharing';
import moment from 'moment';
import qs from 'qs';
const MY_UID_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';
const { Option } = Select;
const { TextArea } = Input;

export default class CaseSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            patient: null,
            hospital_id: null,
            patient_id: null,
            reference_id: null,
            form_id: "",
            allForms: [],
            optional_template: [],
            allPatientForms: [],
            allPatientFormsList: [],
            allCreatedFormsFields: [],
            tempFormHtmlData: [],
            tempResultHtmlData: [],
            tempGraphHtmlData: [],
            formSectionsId: [],
            ResultSectionsId:[],
            allPatientFormsFields: [],
            ExistingInvOrdersListed: [],
            form_name: "",
            patientSelectedForm: "",
            templateData: "",
            template: "",
            last_template: "",
            template_id: "",
            patientData: {},
            modalLoader: true,
            searchLoader: true,
            is_authorized: -1,
            initialSearchText: null,
            initialDocSearchText: null,
            initialSpecSearchText: null,
            initialPatientFormText: null,
            
            defaultRadio: "Routine",
            
            isFormsButton: false,
            isGraphButton: false,
            isVitalButton: false,
            isResultButton: false,
            isKeyFindingButton: false,
            isDischargeSummaryButton: false,
            patientFormActive: false,
            graphTabSelected: "month",
            isAllGraphChecked: false,
            patientGraphList: [],
            graphSelectedKeys: [],
            editorInstance: null,
            speech: {
                identifier: null,
                state: false
            },
            userId: LS_SERVICE.get('staff_id'),
            facilityId: LS_SERVICE.get('FACILITY_ID'),
            enterpriseId: LS_SERVICE.get('ENTERPRISE_ID'),
            currentUserName: LS_SERVICE.get('doctor').name,
            viewModal: false,
            timelineData: [],
            admittingPractitionerRegNo: "",
            pageId : 291,
            resultModal:false,
            keyFindingsNewDataFormatted: {},
            isKeyFindingsLoaded: false,
            kfChartData: {},
            keyFindingsNewViewModal: false,
            keyFindingsChartsTab: "2",
            keyFindingsChartsType: "all",
            keyFindingsChartHeading: "Key Findings",
            keyFindingChartModalNew: false,
            searchTestValue: '',
            addResultBtn: true,
            selectedResultData: {},
            dischargeTemplate: '',
            specialityListData: [],
            speciality_selected: [],
            selected_specialities: [],
            defaultDoctorSpeciality : "",
            formTemplateSelected : false,
            resultTemplateSelected : false,
        }
        this.editorRef = React.createRef();
        this.caseSummaryForm = React.createRef();
        this.InvestigationOrdersListArr = [];
        this.searchTestResult = this.searchTestResult.bind(this);
    }

    async componentDidMount() {
        Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;

        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id
        }

        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
        hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);
        this.setState({
            hospital_id,
            patient_id,
            reference_id,
        }, async () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj.opd_layout }); // IMPORTANT TO HIT AS PER THE VIEW
                await this.getPatientData();
                await this.getPatientDetails();
                await this.specialityList();
                await this.onLoadFormData();
                await this.loadLastCaseSUMMARYSavedTemplate();
            }
        });
    }

    getPatientData = async _ => {
    const { match: { params } } = this.props;
    const { reference_id } = params;
    await Axios.get(PATIENT_DATA({ reference_id }))
      .then(async success => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id
        }
        await this.setState({
          patient,
        });
      })
      .catch(err => console.log(err))
  }

    //Reload template 
    onRelaodTemplate = async (event) => {
        await this.setState({ searchLoader: true }
        , async () => {
            await this.getPatientDetails();
        });
        await this.caseSummaryForm.current.setFieldsValues({ initialSearchText: event.value , speciality_selected : event.speciality_selected });
    }

    saveTemplate = async (flag, c) => {
        if (!this.state.form_id) {
            notification.error({
                message: 'Please select one form then proceed.',
                placement: "topRight",
            });
            return;
        }
        const { patient } = this.state;
        this.setState({ searchLoader: true });
        let selected_speciality = null;
        let selected_specialities = null;
        if(this.state.speciality_selected.length > 0)
        {
            selected_speciality  = this.state.speciality_selected.toString();
        }
        if(this.state.selected_specialities.length > 0)
        {
            selected_specialities  = this.state.selected_specialities.toString();
        }
        let BODY = {
            "form_name": this.state.form_name,
            "form_Id": this.state.form_id,
            "enterprise_Id": LS_SERVICE.get('ENTERPRISE_ID'),
            "patient_Id": patient.mhea_patient_id,
            "facility_Id": LS_SERVICE.get('FACILITY_ID'),
            "user_Id": LS_SERVICE.get('staff_id'),
            "template": this.state.template,
            "optional_template": JSON.stringify(this.state.optional_template),
            "user_name": this.state.currentUserName,
            "flag": flag,
            "is_authorized" : (flag == 3) ? 0 : flag,
            "doctor_id" : LS_SERVICE.get('staff_id'),
            "doctor_name" : this.state.currentUserName,
            "doctor_speciality_id" : LS_SERVICE.get('speciality_id'),
            "doctor_speciality_name" : this.state.defaultDoctorSpeciality,
            "allowed_specialities" :selected_speciality,
            "selected_specialities" :selected_specialities,
            "source" : "opd"
        };

        await Axios.post(CASE_SUMMARY_EDITOR_SAVE, BODY)
            .then(response => {
                if (response.data.data) {
                    var formattedTemplate = response?.data?.data[0].template;

                    
                    this.setState({ template_id: response?.data?.data[0].id, searchLoader: false, is_authorized: (flag == 3) ? 0 : flag });
                    this.caseSummaryForm.current.setFieldsValue({ initialSearchText: this.state.form_id , speciality_selected : this.state.speciality_selected });

                    var message = "Case summary has been saved successfully.";
                    if (flag === 1) {
                        message = "Case summary has been saved and authorized successfully.";
                        
                    }
                    if (flag === 3) {
                        message = "Case summary has been cancel authorized successfully.";
                    }
                    notification.success({
                        message: message,
                        placement: "topRight",
                    });

                } else {
                    notification.error({
                        message: 'Oops! Something snapped. Please try again.',
                        placement: "topRight",
                    });
                    this.setState({ searchLoader: false });
                }
            }).catch(err => {
                notification.error({
                    message: 'Oops! Something snapped. Please try again.',
                    placement: "topRight",
                });
                this.setState({ searchLoader: false });
            });
        this.handleCloseModal();
        this.handleViewTimeline();
    }

    handleViewTimeline = async (form_id) => {
        const { patient } = this.state;
        let Body = {
            template_id : this.state.template_id
        }
        await Axios.post(CASE_SUMMARY_TIMELINE, Body).then(response => {
            if (response.data.status) {
                if(response.data.data.length > 0)
                {
                    this.setState({ timelineData: response.data.data });
                }
            }
        })

    }

    //Print PDF
    printPDFDocument = async (modalName) => {
        if (this.state.template_id == "") {
            notification.error({
                message: 'Oops! Something snapped. Please try again.',
                placement: "topRight",
            });
            return;
        }
        this.setState({ searchLoader: true });
        const BODY =
            await Axios.post(CASE_SUMMARY_EDITOR_PRINT, {
                "templateId": this.state.template_id, "source" : "opd"
            }).then(response => {
                if (response.data.status === "success") {
                    this.pdf_url = response.data.pdf_url;
                    if (modalName != 'save_auth_pop') {
                        window.open(response.data.pdf_url, "_blank");
                        notification.success({
                            message: 'PDF generated successfully.',
                            placement: "topRight",
                        });
                        this.setState({ searchLoader: false });
                    }
                } else {
                    notification.error({
                        message: 'Oops! Something snapped. Please try again.',
                        placement: "topRight",
                    });
                    this.setState({ searchLoader: false });
                }
            }).catch(err => {
                notification.error({
                    message: 'Oops! Something snapped. Please try again.',
                    placement: "topRight",
                });
                this.setState({ searchLoader: false });
            });
    }

    //Load saved last template
    loadLastCaseSUMMARYSavedTemplate = async () => {
        const { patient } = this.state;
        this.setState({ searchLoader: true, template: "" });
        //Check if editor form already exists
        const POST_BODY = {
            "enterprise_Id": LS_SERVICE.get('ENTERPRISE_ID'),
            "facility_Id": LS_SERVICE.get('FACILITY_ID'),
            "patient_Id": parseInt(patient.mhea_patient_id),
            "user_Id": LS_SERVICE.get('staff_id'),
            "doctor_speciality_id" : LS_SERVICE.get('speciality_id'),
        }
        await Axios.post(PATIENT_CASE_SUMMARY_LAST_TEMPLATE, POST_BODY)
        .then(async response => {
            await this.setState({ form_name: response.data.data.form_name, initialSearchText: response.data.data.form_name });
            if (response.data.data) {
                var formattedTemplate = response.data.data.template;

                //adding font and font size
                let styleAttribute = 'style="font-family: "Times New Roman", Times, serif ; font-size: 12px;"';
                let pattern = /<[^>]+>/g;
                formattedTemplate = formattedTemplate.replace(pattern, match => match.replace('>', ` ${styleAttribute}>`));
                formattedTemplate = formattedTemplate.replaceAll("<!", "<p ");
                formattedTemplate = formattedTemplate.replaceAll("-->", " ");

                if(response.data.data.allowed_specialities != "" && response.data.data.allowed_specialities != null)
                {
                    let speciality_selected = response.data.data.allowed_specialities.split(',');
                    speciality_selected.map((specilaity_data, k) => {
                        this.state.speciality_selected.push(parseInt(specilaity_data));
                    });
                }

                if(response.data.data.selected_specialities != "" && response.data.data.selected_specialities != null)
                {
                    let selected_specialities_name = response.data.data.selected_specialities.split(',');
                    selected_specialities_name.map((specilaity_data_name, k) => {
                        this.state.selected_specialities.push(specilaity_data_name);
                    });
                }

                await this.setState({
                    is_authorized: response.data.data.is_authorized,
                    searchLoader: false,
                    template_id: response.data.data.id,
                    form_id: response.data.data.form_Id,
                    template: formattedTemplate,
                    last_template: formattedTemplate,
                    optional_template: (response.data.data.optional_template === null ? [] : JSON.parse(response.data.data.optional_template)),
                });
                this.caseSummaryForm.current.setFieldsValue({ initialSearchText: this.state.form_id , speciality_selected : this.state.speciality_selected });
                    //Call re-replace template on editor load
                    this.checkButtonsEnable();
                    this.handleViewTimeline();
                }
        }).catch(err => {
            this.setState({ searchLoader: false, template: "", template_id: "" });
        });
    }

    checkButtonsEnable = async () => {
        let templateText = this.state.template.replace(/\n/g, "").replace(/\>[\t ]+\</g, "><");
        // if (templateText.indexOf("[Forms]") > 0) {
        //     this.setState({ isFormsButton: true });
        // }
        if (templateText.indexOf("[vital_graph]") > 0 || templateText.indexOf("[Vital_Graph]") > 0) {
            this.setState({ isGraphButton: true });
        }
        this.setState({
            isVitalButton: true,
            //isResultButton: true,
            isKeyFindingButton: true,
        })
    }

    handleCancelModalChartPopup = () => {
        this.setState({
          keyFindingsNewViewModal: false,
          keyFindingsChartsTab: "2",
        });
    }

    handleCancelChartPopup = () => {
        this.setState({
          keyFindingChartModalNew: false,
        });
    }

    handleModalChartPopup = async (e, popup, data = null) => {
        if (e !== null) e.preventDefault();

        await this.setState(
          {
            kfChartData: data
          },
          () => {
            this.setState({
              [popup]: !this.state[popup],
              keyFindingsChartsType: data.type,
              keyFindingsChartHeading: data.name,
              keyFindingsChartId: data.id ?? null,

            });
          }
        );
    }

    getKeyFindingNew(start_date = moment(moment().format()).subtract(1, 'month').format(), end_date = moment().format()) {
        this.setState({ keyFindingsNewViewModal: true });
        this.setState({ isKeyFindingsLoaded: false });
        const { patient } = this.state;
        let data = {
          facility_id: LS_SERVICE.get('FACILITY_ID'),
          registration_id: patient.mhea_patient_id,
        }

        if (start_date != '' && end_date != '') {
          data.fromDate = start_date;
          data.toDate = end_date;
        }
        Axios.post(KEY_FINDINGS_LIS, data)
        .then(async res => {
            await this.setState({ keyFindingsNewDataFormatted: res.data.data }
            , () => {
                this.setState({ isKeyFindingsLoaded: true })
            });
        }).catch(err => {
            console.log("getKeyFindingNewError", err);
        })
    }

    async handleClickDuration(duration_type, type) {
        this.setState({ keyFindingsChartsTab: type });
        if (duration_type == 'day') {
          let end_date = moment().format();
          let start_date = moment(end_date).subtract(1, 'day').format();
          await this.getKeyFindingNew(start_date, end_date);
        }

        if (duration_type == 'week') {
          let end_date = moment().format();
          let start_date = moment(end_date).subtract(1, 'week').format();
          await this.getKeyFindingNew(start_date, end_date);
        }

        if (duration_type == 'month') {
          let end_date = moment().format();
          let start_date = moment(end_date).subtract(1, 'month').format();
          await this.getKeyFindingNew(start_date, end_date);
        }

        if (duration_type == 'year') {
          let end_date = moment().format();
          let start_date = moment(end_date).subtract(1, 'year').format();
          await this.getKeyFindingNew(start_date, end_date);
        }
    }

    //GET patient details 
    getPatientDetails = async () => {
        this.setState({ searchLoader: true });
        const { patient } = this.state;
        if (patient?.mhea_patient_id && patient?.mhea_patient_id === "") {
            notification.error({
                message: 'Oops! Something snapped. Please try again.',
                placement: "topRight",
            });
            return;
        }
        const BODY = {
            "patientId": patient.mhea_patient_id,
            "doctorId": LS_SERVICE.get('staff_id'),
            "facilityId": LS_SERVICE.get('FACILITY_ID'),
            "enterpriseId": LS_SERVICE.get('ENTERPRISE_ID'),
            "type": [],
            "source" : "opd",
            "response_type": "html"
        }
        await Axios.post(PATIENT_VISIT_DETAILS, BODY)
            .then(response => {
                if (response.status == 200) {
                    this.setState({ patientData: response.data.data.details, searchLoader: false });
                } else {
                    this.setState({ patientData: {}, searchLoader: false });
                }
            }).catch(err => {
                this.setState({ patientData: {}, searchLoader: false });
                notification.error({
                    message: 'Oops! Something snapped. Please try again.',
                    placement: "topRight",
                });
            });
    }

    //Load template on form select
    onFormSelect = async (event,props) => {
        const { patient } = this.state;
        this.setState({ searchLoader: true, template: "" });
        if (props.value == "undefined" || props.value == "") {
            this.setState({ searchLoader: false, template: "" });
            notification.error({
                message: 'Oops! Something snapped. Please try again.',
                placement: "topRight",
            });
            return;
        }
        //Check if editor form already exists
        const POST_BODY = {
            "form_Id": props.value,
            "enterprise_Id": LS_SERVICE.get('ENTERPRISE_ID'),
            "facility_Id": LS_SERVICE.get('FACILITY_ID'),
            "patient_Id": patient.mhea_patient_id,
            "user_Id": LS_SERVICE.get('staff_id'),
            "doctor_speciality_id" : LS_SERVICE.get('speciality_id'),
        }
        await Axios.post(CASE_SUMMARY_EDITOR_TEMPLATE, POST_BODY)
            .then(async response => {
                this.setState({ form_id: props.value, form_name: props.title, initialSearchText: props.value });
                if (response.data.data) {

                    var formattedTemplate = response.data.data.template;

                    if (ADMITTING_PRACTITIONER_DETAILS_PRINT.includes(LS_SERVICE.get('FACILITY_ID')))
                    {
                        formattedTemplate = this.appendAdmittingData(formattedTemplate);
                    }

                    //adding font and font size
                    let styleAttribute = 'style="font-family: "Times New Roman", Times, serif ; font-size: 12px;"';
                    let pattern = /<[^>]+>/g;
                    formattedTemplate = formattedTemplate.replace(pattern, match => match.replace('>', ` ${styleAttribute}>`));
                    formattedTemplate = formattedTemplate.replaceAll("<!", "<p ");
                    formattedTemplate = formattedTemplate.replaceAll("-->", " ");

                    await this.setState({
                        is_authorized: response.data.data.is_authorized,
                        searchLoader: false,
                        template_id: response.data.data.id,
                        template: formattedTemplate,
                        speciality_selected: [],
                        selected_specialities: [],
                        optional_template: (response.data.data.optional_template === null ? [] : JSON.parse(response.data.data.optional_template))
                    });
                    if(response.data.data.allowed_specialities != "" && response.data.data.allowed_specialities != null)
                    {
                        let speciality_selected = response.data.data.allowed_specialities.split(',');
                        speciality_selected.map((specilaity_data, k) => {
                            this.state.speciality_selected.push(parseInt(specilaity_data));
                        });
                        //this.caseSummaryForm.current.setFieldsValue({ speciality_selected: this.state.speciality_selected });
                    }
                    if(response.data.data.selected_specialities != "" && response.data.data.selected_specialities != null)
                    {
                        let selected_specialities_name = response.data.data.selected_specialities.split(',');
                        selected_specialities_name.map((specilaity_data_name, k) => {
                            this.state.selected_specialities.push(specilaity_data_name);
                        });
                    }
                    this.caseSummaryForm.current.setFieldsValue({ initialSearchText: this.state.form_id , speciality_selected : this.state.speciality_selected });
                    //this.checkButtonsEnable();
                    this.handleViewTimeline();
                } else {
                    const BODY = {
                        "form_Id": props.value,
                        "enterprise_Id": LS_SERVICE.get('ENTERPRISE_ID'),
                        "facility_Id": LS_SERVICE.get('FACILITY_ID'),
                        "patient_Id": patient.mhea_patient_id,
                        "signature": true,
                        "user_Id": LS_SERVICE.get('staff_id'),
                        "type" : 2,
                    };
                    Axios.post(CASE_SUMMARY_TEMPLATE, BODY)
                        .then(async response => {
                            var formattedTemplate = `<span><b>${'&nbsp;'.repeat(60)}`;
                            formattedTemplate = formattedTemplate + props.title + `</b></br></span>`;
                            if (response.data.data) {
                                formattedTemplate = formattedTemplate+response.data.data.template;
                                formattedTemplate = formattedTemplate.replaceAll("<!", "<p ");
                                formattedTemplate = formattedTemplate.replaceAll("-->", " ");
                                let patientData = this.state.patientData;
                                let dischargeText = "";
                                if (typeof patientData != 'undefined' && Object.keys(patientData).length != 0) {
                                    for (var key in await patientData) {
                                        if (typeof patientData[key] === 'object') {
                                            for (var k in await patientData[key]) {
                                                if (key === "discharge_medications_as_per_attached_report") {
                                                    dischargeText += patientData[key][k];
                                                }
                                                formattedTemplate = formattedTemplate.replace("[" + k + "]", patientData[key][k]);
                                            }
                                            if (dischargeText !== "") {
                                                formattedTemplate = formattedTemplate.replace("[discharge_medications_as_per_attached_report]", dischargeText);
                                            }
                                        } else {
                                            formattedTemplate = formattedTemplate.replace("[" + key + "]", patientData[key]);
                                        }
                                    }
                                }
                                if (ADMITTING_PRACTITIONER_DETAILS_PRINT.includes(LS_SERVICE.get('FACILITY_ID')))
                                {
                                    formattedTemplate = this.appendAdmittingData(formattedTemplate);
                                }
                                console.log(formattedTemplate);
                                this.setState({
                                    is_authorized: -1,
                                    searchLoader: false,
                                    //template_id: response.data.data.id,
                                    template: formattedTemplate,
                                    optional_template: [],
                                    speciality_selected: [],
                                    selected_specialities: [],
                                });
                                var admittingPractitionerSpecialityId = LS_SERVICE.get('speciality_id');
                                this.state.speciality_selected.push(parseInt(admittingPractitionerSpecialityId));
                                this.state.selected_specialities.push(this.state.defaultDoctorSpeciality);
                            } else {
                                this.setState({ is_authorized: -1, searchLoader: false, template: formattedTemplate, optional_template: [] });
                            }
                            this.caseSummaryForm.current.setFieldsValue({ initialSearchText: this.state.form_id , speciality_selected : this.state.speciality_selected });
                            this.checkButtonsEnable();
                            this.handleViewTimeline();
                        }).catch(err => {
                            this.setState({ searchLoader: false, template: "" });
                            notification.error({
                                message: 'Oops! Something snapped. Please try again.',
                                placement: "topRight",
                            });
                        });
                }
            }).catch(err => {
                this.setState({ searchLoader: false, template: "" });
                notification.error({
                    message: err,
                    placement: "topRight",
                });
            });
    }

    appendAdmittingData(template) {
        let newTemp = template;
        let formattedTemplate = "";
        formattedTemplate = newTemp + `<span>
        </br>
        <b>${LS_SERVICE.get('doctor').name}</b>
        </br>
        <b>${LS_SERVICE.get('doctor').code}</b></span>`;
        return formattedTemplate;
    }

    /*Modal show/hide starts */
    handleShowModal = async (modalName) => {
        const { patientGraphList, allPatientForms, allPatientFormsList } = this.state;
        if (modalName === "forms") {
            this.setState({
                patientSelectedForm: "",
                patientFormActive: false,
                allCreatedFormsFields: [],
                allPatientFormsFields: [],
            }, async () => {
                //if (allPatientForms.length === 0 || allPatientFormsList.length === 0) {
                    await this.getPateintFormData();
                    this.setState({ show: modalName });
                // } else {
                //     this.setState({ show: modalName });
                // }
            });
        } else if (modalName === "graphs") {
            if (patientGraphList.length === 0) {
                await this.getPateintGraphData();
                this.setState({ show: modalName });
            } else {
                this.setState({ show: modalName });
            }
        } 
        else if (modalName === "results") {
            this.setState({
            }, async () => {
                await this.getCurrentInvOrdersList();
                this.setState({ resultModal: true });
            });
        }
        else if (modalName === "vitals") {
            this.setState({
            }, async () => {
                this.setState({ show: modalName });
            });
        }
        else {
            this.setState({ show: modalName });
        }
    }

    getCurrentInvOrdersList = async () => {
        const { patient } = this.state;
        this.setState({ modalLoader: false, ExistingInvOrdersListed: [] });

        let PARAMS = {
          facilityId: parseInt(LS_SERVICE.get('FACILITY_ID')),
          patientId: parseInt(patient.mhea_patient_id),
          service_type: 'both',
          timezoneOffsetMinutes: parseInt(330)
        };
        PARAMS.encounterId = parseInt(0);
        PARAMS.status = 0;
        await Axios.post(EXISTING_ORDERS_LIST, PARAMS)
          .then(success => {
            if (success?.data?.status == true) {
                this.setState({ modalLoader: true });

              if (success.data.data.length > 0) {
                this.InvestigationOrdersListArr = success.data.data.filter((v, i) => v.serviceCategoryCode == "I" || v.serviceCategoryCode == "P" || v.serviceCategoryCode == "O")
              } 
              if (this.InvestigationOrdersListArr.length > 0) {
                 this.setState({ ExistingOrdersListed: this.InvestigationOrdersListArr, ExistingOrdersListedOriginal: this.InvestigationOrdersListArr})
              }
            }
        }).catch(err => console.log(err))
    }

    handleTestDetails = async (data) => {
        const Parameter = {
            enterprise_id: LS_SERVICE.get('ENTERPRISE_ID'),
            facility_id: LS_SERVICE.get('FACILITY_ID'),
            registration_id: 0,
            registration_no: "",
            encounter_id: 0,
            encounter_no: "",
            serviceorder_id: data.orderId,
            serviceorderdtl_id: data.id,
            service_id: data.serviceId,
            service_name: "",
            fromDate: null,
            toDate: null,
            userId: LS_SERVICE.get('staff_id')
        }
        await Axios.post(LIS_ORDERS_RESULT, Parameter)
          .then(async success => {
            if (success?.data?.status == true) {
              if (success.data.data.length > 0) {
                await this.setState({
                    selectedResultData : success.data.data[0],
                })
              }
              else
              {
                await this.setState({
                    selectedResultData : {},
                })
              } 
            }
        }).catch(err => console.log(err))
    }

    handleCloseModal = (id) => {
        this.setState({ show: id });
    }

    handleResultModalCancel() {
        this.setState({ resultModal: false, selectedResultData : {}, searchTestValue : '' })
    }

    /*Forms works start here */
    getPateintFormData = async () => {
        this.setState({ modalLoader: true });
        const { patient } = this.state;
        const PARAMS = { "registrationId": patient.mhea_patient_id, "encounterId": 0 };
        await Axios.post(Discharge_Summary_Patient_Forms, PARAMS)
            .then(resp => {
                if (resp.data.data) {
                    this.setState({
                        allPatientForms: resp.data.data.data,
                        allPatientFormsList: resp.data.data.data,
                        formSelected: '',
                        modalLoader: false
                    }, () => { this.optionPatientFormLoad() });
                }
            }).catch(err => {
                notification.error({
                    message: 'Oops! Something snapped. Please try again.',
                    placement: "topRight",
                });
            });
    }

    optionPatientFormLoad() {
        if (!this.state.allPatientForms || this.state.allPatientForms.length === 0) {
            return (<Option key={0} value={0}><span>No Result Found</span></Option>)
        }
        return this.state.allPatientForms.map((obj, i) => {
            return (<Option key={i} value={obj.id} label={(obj.formName).toString()}>{obj.formName}</Option>
            )
        }, this);
    }
    onPatientFormChange = async (event,props) => {
        if (props.value == "undefined" || props.value == "") {
            notification.error({
                message: 'Oops! Something snapped. Please try again.',
                placement: "topRight",
            });
            return;
        }
        this.setState({
            formSelected: props.value
        });
        //Filter patients list
        this.setState({ modalLoader: true, patientSelectedForm: props.label });
        let filteredItems = await this.filterPatientFormItems(this.state.allPatientForms, props);
        this.setState({
            allPatientFormsList: filteredItems,
            allPatientFormsFields: [],
            allCreatedFormsFields: [],
            modalLoader: false
        });
    }
    filterPatientFormItems = async (arr, event) => {
        return await arr.filter(function (el) {
            return el.formName == event.label
        });
    }
    getPateintFormFieldsData = async (event) => {
        if (event.target.id == "undefined" || event.target.id == "") {
            this.setState({ modalLoader: false, searchLoader: false });
            notification.error({
                message: 'Oops! Something snapped. Please try again.',
                placement: "topRight",
            });
            return;
        }
        this.setState({ modalLoader: true, allCreatedFormsFields: [], searchLoader: false, docFormId: event.target.id, patientSelectedForm: event.target.formName, patientFormActive: true });
        const PARAMS =
        {
            "id": event.target.id,
            "formid": event.target.formId,
            "enterpriseId": LS_SERVICE.get('ENTERPRISE_ID'),
            "facilityId": LS_SERVICE.get('FACILITY_ID'),
            "encounterId": this.state.encounter_Id,
            "registrationId": this.state.patient_Id,
            "userGroupId": this.state.userGroupId
        }
        await Axios.post(Discharge_Summary_Patient_Forms_Fields, PARAMS)
            .then(async resp => {
                if (resp.data.data.statusCode === 200) {
                    //Crete row data                    
                    let responseData = resp.data.data.data;
                    let createdItems = [];
                    if (responseData && responseData.length) {
                        responseData.forEach(async (element, i) => {
                            let innerSection = element.vw_frmformstaggingdetails;
                            if (innerSection && innerSection.length) {
                                innerSection.forEach(async (object, j) => {
                                    createdItems = await this.createFieldItems(createdItems, object, this.state.patientSelectedForm);
                                });
                            }
                        });
                    }
                    await this.setState({
                        allCreatedFormsFields: createdItems,
                        allPatientFormsFields: resp.data.data.data,
                        modalLoader: false
                    });
                } else {
                    this.setState({ allPatientFormsFields: [], allCreatedFormsFields: [], modalLoader: false });
                    notification.error({
                        message: 'Oops! Something snapped. Please try again.',
                        placement: "topRight",
                    });
                }
            }).catch(err => {
                this.setState({ allPatientFormsFields: [], allCreatedFormsFields: [], modalLoader: false });
                notification.error({
                    message: 'Oops! Something snapped. Please try again.',
                    placement: "topRight",
                });
            });
    }
    createFieldItems = async (createdItems, element, patientSelectedForm) => {
        if (Object.keys(element).length != 0) {
            for (var key in element) {
                let fieldObj = {};
                //Checks for editor elements
                if (element.hasOwnProperty(key) && key === "columnComponentName1" && element["columnComponentName1"] == "wordeditor") {
                    if (element.hasOwnProperty(key) && key === "columnComponentName1" && element["columnComponentName1"] !== "" && element["userValue1"] !== "") {
                        fieldObj = { "id": uuidv5(key, MY_UID_NAMESPACE) + element["id"], "formName": patientSelectedForm, "title": "", "value": element["userValue1"] };
                        createdItems.push(fieldObj);
                    }
                    if (element.hasOwnProperty(key) && key === "columnComponentName2" && element["columnComponentName2"] !== "" && element["userValue2"] !== "") {
                        fieldObj = { "id": uuidv5(key, MY_UID_NAMESPACE) + element["id"], "formName": patientSelectedForm, "title": "", "value": element["userValue2"] };
                        createdItems.push(fieldObj);
                    }
                    if (element.hasOwnProperty(key) && key === "columnComponentName3" && element["columnComponentName3"] !== "" && element["userValue3"] !== "") {
                        fieldObj = { "id": uuidv5(key, MY_UID_NAMESPACE) + element["id"], "formName": patientSelectedForm, "title": "", "value": element["userValue3"] };
                        createdItems.push(fieldObj);
                    }
                } else {
                    //Checks for form builder elements
                    if (element.hasOwnProperty(key) && key === "columnComponentName1" && element["columnComponentName1"] !== "" && element["userValue1"] !== "") {
                        fieldObj = { "id": uuidv5(key, MY_UID_NAMESPACE) + element["id"], "formName": patientSelectedForm, "title": element["columnComponentName1"], "value": element["userValue1"] };
                        createdItems.push(fieldObj);
                    }
                    if (element.hasOwnProperty(key) && key === "columnComponentName2" && element["columnComponentName2"] !== "" && element["userValue2"] !== "") {
                        fieldObj = { "id": uuidv5(key, MY_UID_NAMESPACE) + element["id"], "formName": patientSelectedForm, "title": element["columnComponentName2"], "value": element["userValue2"] };
                        createdItems.push(fieldObj);
                    }
                    if (element.hasOwnProperty(key) && key === "columnComponentName3" && element["columnComponentName3"] !== "" && element["userValue3"] !== "") {
                        fieldObj = { "id": uuidv5(key, MY_UID_NAMESPACE) + element["id"], "formName": patientSelectedForm, "title": element["columnComponentName3"], "value": element["userValue3"] };
                        createdItems.push(fieldObj);
                    }
                    //patientDetailListData
                    if (element.hasOwnProperty(key) && key === "columnComponentName1" && element["columnComponentName1"] !== "" && element["patientDetailListData1"].length) {
                        createdItems = await this.iterateInnerFields(createdItems, key, element, patientSelectedForm, 1);
                    }
                    if (element.hasOwnProperty(key) && key === "columnComponentName2" && element["columnComponentName2"] !== "" && element["patientDetailListData2"].length) {
                        createdItems = await this.iterateInnerFields(createdItems, key, element, patientSelectedForm, 2);
                    }
                    if (element.hasOwnProperty(key) && key === "columnComponentName3" && element["columnComponentName3"] !== "" && element["patientDetailListData3"].length) {
                        createdItems = await this.iterateInnerFields(createdItems, key, element, patientSelectedForm, 3);
                    }
                }
            }
            return await createdItems;
        }
    }
    iterateInnerFields = async (createdItems, key, elementArr, patientSelectedForm, index) => {
        let patientDetails = elementArr["patientDetailListData" + index];
        let fieldObj = {};
        patientDetails.forEach(async (element, i) => {
            fieldObj = { "id": uuidv5(key, MY_UID_NAMESPACE) + elementArr["id"], "formName": patientSelectedForm, "title": elementArr["columnComponentName" + index], "value": element.referenceName };
            await createdItems.push(fieldObj);
        });
        return await createdItems;
    }
    insertHtml = async (event) => {
        const { formSectionsId, tempFormHtmlData } = this.state;
        if (event.target.checked) {
            if (formSectionsId.indexOf(event.target.id) == -1) {
                formSectionsId.push(event.target.id);
            }
            const formName = event.target.formName;
            let targetText = event.target.text + ", ";
            let existKey = -1;
            if (tempFormHtmlData && tempFormHtmlData.length) {
                let index = tempFormHtmlData.findIndex(obj => obj.formName == formName);
                existKey = (index == -1) ? -1 : index;
            }
            if (existKey === -1) {
                const htmlObj = { "id": event.target.id, "formName": event.target.formName, "text": targetText }
                tempFormHtmlData.push(htmlObj);
            } else {
                targetText = tempFormHtmlData[existKey]['text'] + targetText;
                tempFormHtmlData[existKey]['text'] = targetText;
            }
            this.setState({ tempFormHtmlData: tempFormHtmlData, formSectionsId: formSectionsId });
        }
        else {
            const tcIndex = formSectionsId.indexOf(event.target.id);
            if (tcIndex != -1) {
                formSectionsId.splice(tcIndex, 1);
            }
            const formName = event.target.formName;
            let targetText = event.target.text + ", ";
            let index = tempFormHtmlData.findIndex(obj => obj.formName == formName);
            let existKey = (index == -1) ? "" : index;
            const existingtext = tempFormHtmlData[existKey]['text'];
            let editorFormText = existingtext.replace(targetText, "");
            tempFormHtmlData[existKey]['text'] = editorFormText;
            //Remove item if no data in element
            if (tempFormHtmlData[existKey]['text'] === "") {
                tempFormHtmlData.splice(index, 1);
            }
            this.setState({ tempFormHtmlData: tempFormHtmlData, formSectionsId: formSectionsId });
        }
    }

    /********************************* Result Insert *****************************/
    insertResultHtml = async (event) => {
        const { ResultSectionsId, tempResultHtmlData } = this.state;
        if (event.target.checked) {
            if (ResultSectionsId.indexOf(event.target.id) == -1) {
                ResultSectionsId.push(event.target.id);
            }
            const resultName = event.target.resultName;
            const resultDate = event.target.resultDate;
            let targetText = event.target.text + ", ";
            let existKey = -1;
            if (tempResultHtmlData && tempResultHtmlData.length) {
                let index = tempResultHtmlData.findIndex(obj => obj.resultName == resultName);
                existKey = (index == -1) ? -1 : index;
            }
            if (existKey === -1) {
                const htmlObj = { "id": event.target.id, "resultName": event.target.resultName, "resultDate": event.target.resultDate, "text": targetText }
                tempResultHtmlData.push(htmlObj);
            } else {
                targetText = tempResultHtmlData[existKey]['text'] + targetText;
                tempResultHtmlData[existKey]['text'] = targetText;
            }
            this.setState({ tempResultHtmlData: tempResultHtmlData, ResultSectionsId: ResultSectionsId });
        }
        else {
            const tcIndex = ResultSectionsId.indexOf(event.target.id);
            if (tcIndex != -1) {
                ResultSectionsId.splice(tcIndex, 1);
            }
            const resultName = event.target.resultName;
            let targetText = event.target.text + ", ";
            let index = tempResultHtmlData.findIndex(obj => obj.resultName == resultName);
            let existKey = (index == -1) ? "" : index;
            const existingtext = tempResultHtmlData[existKey]['text'];
            let editorFormText = existingtext.replace(targetText, "");
            tempResultHtmlData[existKey]['text'] = editorFormText;
            //Remove item if no data in element
            if (tempResultHtmlData[existKey]['text'] === "") {
                tempResultHtmlData.splice(index, 1);
            }
            this.setState({ tempResultHtmlData: tempResultHtmlData, ResultSectionsId: ResultSectionsId });
        }
    }

    handleCaseSheetSummaryVitals = async (visitVitals, vitalsConstant) => {
        await this.existingVitalSave(visitVitals,vitalsConstant);
    }

    /******************************** Vitals Insert ***********************/
    existingVitalSave = async (visitVitals,vitalsConstant) => {

        const { template } = this.state;
        let insertNoneHtml = "";
        let appenedVitalHtml = "";
        if(visitVitals.length > 0)
        {

            var table_headers = [];

            appenedVitalHtml = '<div className="table-NM"><table class="vital-recorded" className="table table-bordered" ><tr className="row-bgdark"><th>Date & Time</th>';
            visitVitals.map((vitals_details_data, index) => {
                vitalsConstant.map((vitals_data, k) => {
                    if (vitals_details_data[vitals_data.id] != "" && vitals_details_data[vitals_data.id] != undefined && !table_headers.includes(vitals_data.id))
                    {
                      table_headers.push(vitals_data.id);
                    }
                })
            });

            if(table_headers.length > 0)
            {
              table_headers = table_headers.sort();
              vitalsConstant.map((vitals_data, k) => {
                if (vitals_data.id != 'V005' && vitals_data.id != 'V006' && table_headers.includes(vitals_data.id)) {
                  appenedVitalHtml += '<th>' + vitals_data.display_name + '</th>';
                }
                if (vitals_data.id == 'V005' && table_headers.includes(vitals_data.id)) {
                  appenedVitalHtml += '<th>BP</th>';
                }
              });
            }
            visitVitals.map((vitals_details_data, index) => {
                appenedVitalHtml += '</tr><tr><td>' + moment(vitals_details_data.date, 'YYYY-MM-DD HH:mm:ss').add('19800','seconds').format('DD MMM, YYYY | hh:mm A') + '</td>';
                vitalsConstant.map((vitals_data, k) => {
                    if (vitals_data.id != null && vitals_data.id != '' && vitals_data.id != 'V005' && vitals_data.id != 'V006' && table_headers.includes(vitals_data.id)) {
                        if (vitals_details_data[vitals_data.id] != "" && vitals_details_data[vitals_data.id] != undefined) {
                            appenedVitalHtml += '<td>' + vitals_details_data[vitals_data.id] + " " + vitals_data.display_value + '</td>';
                        }
                        else {
                            appenedVitalHtml += '<td></td>';
                        }
                    }
                    if (vitals_data.id != null && vitals_data.id != '' && vitals_data.id == 'V005' && table_headers.includes(vitals_data.id))
                    {
                        if (vitals_details_data[vitals_data.id] != '' && vitals_details_data[vitals_data.id] != undefined) {
                        appenedVitalHtml += '<td>' + vitals_details_data.V006 + "/" + vitals_details_data.V005 + " " + vitals_data.display_value + '</td>';
                        }
                        else {
                        appenedVitalHtml += '<td></td>';
                        }
                    }
                });
            });

            appenedVitalHtml += '</tr></table></div>';

            let newAppendHtml = '<div class="vitals_container">';
            newAppendHtml += appenedVitalHtml; 
            newAppendHtml += "<div style='display: none;'>[Vitals]</div></div>";

            let vitalTemplateText = template.replace(/\n/g, "").replace(/\>[\t ]+\</g, "><");
            if (vitalTemplateText.indexOf("[vitals]") == -1) {
                if (vitalTemplateText.indexOf("[Vitals]") > 0) {
                    let starting_vital_text = vitalTemplateText.indexOf('<div class="vitals_container"');
                    let last_vital_text = vitalTemplateText.lastIndexOf('[Vitals]</div></div>');
                    let vital_html = vitalTemplateText.substring(starting_vital_text, last_vital_text);
                    let vital_html_append = vital_html + "[Vitals]</div></div>";
                    if(vital_html && vital_html != "" && vital_html != undefined)
                    {
                        let newAppendHtml = "";
                        newAppendHtml = '<div class="vitals_container">';
                        newAppendHtml += appenedVitalHtml; 
                        newAppendHtml += "<div style='display: none;'>[Vitals]</div></div>";
                        newAppendHtml = vital_html_append + newAppendHtml;
                        vitalTemplateText = vitalTemplateText.replace(vital_html, newAppendHtml);                      
                    }
                }
                else
                {
                    let newVitalAppendHtml = '<div class="vitals_container"><strong>Vitals</strong><br>';
                    newVitalAppendHtml += appenedVitalHtml;
                    newVitalAppendHtml += "<div style='display: none;'>[Vitals]</div></div>";
                    vitalTemplateText = vitalTemplateText + newVitalAppendHtml;
                }
            }
            else
            {
                vitalTemplateText = vitalTemplateText.replace("[vitals]", newAppendHtml);
            }
            
            this.setState({ searchLoader: false, template: vitalTemplateText });
            this.handleCloseModal();
        }        
    }

    checkKeyExistArray(key) {
        let list = this.state.optional_template;
        for (var i = 0; i < list.length; i++) {
            if (key in list[i]) {
                return { "exist": true, "index": i };
            }
        }
        return { "exist": false, "index": null };
    }
    existingFormSave = async (event) => {
        const { template, tempFormHtmlData, optional_template, formTemplateSelected } = this.state;
        let insertNoneHtml = "";
        let appenedFormHtml = "";
        tempFormHtmlData.map((data, k) => {
            let formName = data.formName;
            let className = formName.replace(/\s/g, "_");
            //Remove last comma
            let formText = data.text;
            formText = formText.replace(/,\s*$/, "");
            appenedFormHtml += '<div key="' + k + '" class="' + className + '"><b>' + data.formName + ':</b>&nbsp;' + formText + insertNoneHtml + '</div>';
        });
        let newAppendHtml = '<div class="form_container">';
        newAppendHtml += appenedFormHtml; 
        newAppendHtml += "<div style='display: none;'>[Forms_Data]</div></div>";
        let formTemplateText = template.replace(/\n/g, "").replace(/\>[\t ]+\</g, "><");
        if (formTemplateText.indexOf("[forms]") == -1) {
            if (formTemplateText.indexOf("[Forms_Data]") > 0) {
                let starting_form_text = formTemplateText.indexOf('<div class="form_container"');
                let last_form_text = formTemplateText.lastIndexOf('[Forms_Data]</div></div>');
                let form_html = formTemplateText.substring(starting_form_text, last_form_text);
                let form_html_append = form_html + "[Forms_Data]</div></div>";
                if(form_html && form_html != "" && form_html != undefined)
                {
                    if(formTemplateSelected == false)
                    {
                        this.setState({
                            original_form_template : form_html_append
                        })
                    }
                    let newAppendHtml = "";
                    newAppendHtml = '<div class="form_container">';
                    newAppendHtml += appenedFormHtml; 
                    newAppendHtml += "<div style='display: none;'>[Forms_Data]</div></div>";
                    if (optional_template && optional_template.length) {
                        const resp = this.checkKeyExistArray("forms_html");
                        if (!resp.exist) {
                            optional_template.push({ "forms_html": newAppendHtml });
                        } else {
                            optional_template[resp.index]["forms_html"] = newAppendHtml;
                        }
                    } else {
                        optional_template.push({ "forms_html": newAppendHtml });
                    }
                    if(this.state.is_authorized >=0)
                    {
                        if(this.state.original_form_template != "" && this.state.original_form_template != undefined)
                        {
                            newAppendHtml = this.state.original_form_template + newAppendHtml;
                        }
                        formTemplateText = formTemplateText.replace(form_html_append, newAppendHtml);
                    }
                    else
                    {
                        formTemplateText = formTemplateText.replace(form_html_append, newAppendHtml);
                    }
                    
                }
            }
            else
            {
                let newFormAppendHtml = '<div class="form_container"><strong>Forms</strong><br>';
                newFormAppendHtml += appenedFormHtml;
                newFormAppendHtml += "<div style='display: none;'>[Forms_Data]</div></div>";
                formTemplateText = formTemplateText + newFormAppendHtml;
            }
        }
        else
        {
            if (optional_template && optional_template.length) {
                const resp = this.checkKeyExistArray("forms_html");
                if (!resp.exist) {
                    optional_template.push({ "forms_html": newAppendHtml });
                } else {
                    optional_template[resp.index]["forms_html"] = newAppendHtml;
                }
            } else {
                optional_template.push({ "forms_html": newAppendHtml });
            }
            formTemplateText = formTemplateText.replace("[forms]", newAppendHtml);
        }
        formTemplateText = formTemplateText.replace("<p>undefined</p>", " ");
        this.setState({ searchLoader: false, template: formTemplateText, optional_template: optional_template, formTemplateSelected : true });
        this.handleCloseModal();
    }

    /************************Save Result form *****************************/
    existingResultsSave = async (event) => {
        const { template, tempResultHtmlData, optional_template, resultTemplateSelected } = this.state;
        let insertNoneHtml = "";
        let appenedResultHtml = "";
        tempResultHtmlData.map((data, k) => {
            let resultName = data.resultName;
            let resultDate = data.resultDate;
            let className = resultName.replace(/\s/g, "_");
            //Remove last comma
            let resultText = data.text;
            resultText = resultText.replace(/,\s*$/, "");
            appenedResultHtml += '<div key="' + k + '" class="' + className + '"><b>' + data.resultName + '&nbsp; (' + data.resultDate + ') :</b>&nbsp;' + resultText + insertNoneHtml + '</div>';
        });
        let newAppendHtml = '<div class="result_container">';
        newAppendHtml += appenedResultHtml;
        newAppendHtml += "<div style='display: none;'>[Test_Result_Data]</div></div>";
        let resultTemplateText = template.replace(/\n/g, "").replace(/\>[\t ]+\</g, "><");
        if (resultTemplateText.indexOf("[test_results]") == -1) {
            if (resultTemplateText.indexOf("[Test_Result_Data]") > 0) {
                let starting_result_text = resultTemplateText.indexOf('<div class="result_container"');
                let last_result_text = resultTemplateText.lastIndexOf('[Test_Result_Data]</div></div>');
                let result_html = resultTemplateText.substring(starting_result_text, last_result_text);
                let result_html_append = result_html + "[Test_Result_Data]</div></div>";
                console.log(result_html);
                //let resultContainer = document.getElementsByClassName("result_container");
                if (result_html != "" && result_html != undefined) {
                    if(resultTemplateSelected == false)
                    {
                        this.setState({
                            original_result_template : result_html_append
                        })
                    }
                    let newAppendHtml = "";
                    newAppendHtml = '<div class="result_container">';
                    newAppendHtml += appenedResultHtml;
                    newAppendHtml += "<div style='display: none;'>[Test_Result_Data]</div></div>";
                    if (optional_template && optional_template.length) {
                        const resp = this.checkKeyExistArray("result_html");
                        if (!resp.exist) {
                            optional_template.push({ "result_html": newAppendHtml });
                        } else {
                            optional_template[resp.index]["result_html"] = newAppendHtml;
                        }
                    } else {
                        optional_template.push({ "result_html": newAppendHtml });
                    }
                    if(this.state.is_authorized >=0)
                    {
                        if(this.state.original_result_template != "" && this.state.original_result_template != undefined)
                        {
                            newAppendHtml = this.state.original_result_template + newAppendHtml;
                        }
                        resultTemplateText = resultTemplateText.replace(result_html_append, newAppendHtml);
                    }
                    else
                    {
                        resultTemplateText = resultTemplateText.replace(result_html_append, newAppendHtml);
                    }
                }
            }
            else
            {
                let newResultAppendHtml = '<div class="result_container"><strong>Tests/Procedures</strong><br>';
                newResultAppendHtml += appenedResultHtml;
                newResultAppendHtml += "<div style='display: none;'>[Test_Result_Data]</div></div>";
                resultTemplateText = resultTemplateText + newResultAppendHtml;
            }
        } else {
            if (optional_template && optional_template.length) {
                const resp = this.checkKeyExistArray("result_html");
                if (!resp.exist) {
                    optional_template.push({ "result_html": newAppendHtml });
                } else {
                    optional_template[resp.index]["result_html"] = newAppendHtml;
                }
            } else {
                optional_template.push({ "result_html": newAppendHtml });
            }
            resultTemplateText = resultTemplateText.replace("[test_results]", newAppendHtml);
        }
        resultTemplateText = resultTemplateText.replace("<p>undefined</p>", " ");

        this.setState({ searchLoader: false, template: resultTemplateText, optional_template: optional_template, resultTemplateSelected : true });
        this.handleResultModalCancel();
    }

    resetPatientForm = () => {
        this.setState({
            allCreatedFormsFields: [],
            allPatientFormsFields: [],
            patientFormActive: false,
            initialPatientFormText: null,
            patientSelectedForm: "",
            formSelected: '',
        }, async () => {
            await this.getPateintFormData();
        });
    }
    onFormFinishFailed = (errorInfo) => {
        if (!this.state.formSectionsId.length) {
            notification.error({
                message: 'Please select form fields',
                placement: "topRight",
            });
            return;
        }
    }

    onResultFinishFailed = (errorInfo) => {
        if (!this.state.ResultSectionsId.length) {
            notification.error({
                message: 'Please select result fields',
                placement: "topRight",
            });
            return;
        }
    }

    ExistingOrdersListLoad = () => {
        if (!this.state.ExistingOrdersListed || this.state.ExistingOrdersListed.length === 0) {
          return (
            <tr><td colSpan='5' align="center">No Orders</td></tr>
          )
        }
        return this.state.ExistingOrdersListed.map((data, i) => {
          return (
            <tr key={i}>
              <td><Moment parse="YYYY-MM-DDTHH:mm:ss" date={data.enteredDate} format="DD MMM, YYYY | hh:mm A" /></td>
              <td>{data.ordered_Location}</td>
              <td>{data.ordered_by}</td>
              <td>{data.service_name}</td>
              {
                data?.investigationStatus?.toUpperCase().trim() === "FINALIZED" ||
                data?.investigationStatus?.toUpperCase().trim() === "REPORTED" ||
                data?.investigationStatus?.toUpperCase().trim() === "RD" ||
                data?.investigationStatus?.toUpperCase().trim() === "RP" ?
                <td className='cursor-p text-success' onClick={() => { this.handleTestDetails(data) }}>{data.status}</td>
                :
                <td>{data.status}</td>
            }
            </tr>)
        });
    }

    handleTimeline() {
        this.setState({ viewModal: true })
    }

    handleModalCancel() {
        this.setState({ viewModal: false })
    }

    // HTML for List of patient on the my patient page
    timeLineListLoad() {
        try {
          if ((!this.state.timelineData || this.state.timelineData.length === 0)) {
            let htmlData = (
              <tr className="view_details">
                <td className="inner_table text-center" colSpan={10}>No Timeline Found.</td>
              </tr>
            )
            return htmlData;
          }

          return this.state.timelineData.map((obj, i) => {
            let htmlData = (
                <li className="completed">
                    <div className="content left">
                        <span className="text-dark">{obj.action_type}: </span><span className="text-muted">{typeof obj.created_by_name != 'undefined' && obj.created_by_name != null ? obj.created_by_name : ""}</span>
                    </div>
                    <div className="content right">
                        <span className="text-success">
                            {typeof obj.created_at != undefined && obj.created_at != null ?
                                moment(obj.created_at).local().format('DD MMM, YYYY | hh:mm A') : ""}
                        </span>
                    </div>
                </li>
            )
            return htmlData;
          });
        } catch (err) {
          console.log('timeLineListLoad: ', err);
        }
    }

    onLoadFormData = async () => {
        const PARAMS = {
            "active": 1,
            "enterpriseId": LS_SERVICE.get('ENTERPRISE_ID'),
            "description": "",
            "categoryCode": "CSM",
            "facilityId": LS_SERVICE.get('FACILITY_ID'),
            "applicabilityER": false,
            "applicabilityIP": true,
            "applicabilityOP": false,
            "applicabilityDC": false,
        }
        await Axios.post(HIS_FORMS, PARAMS)
        .then(response => {
            var formListIds = [];
            var formList = [];
            let form_list = response.data.data.data;
            const unique = form_list.filter(element => {
                const isDuplicate = formListIds.includes(element.id);
                if (!isDuplicate) {
                  formListIds.push(element.id);
                  formList.push(element);
                  return true;
                }
                return false;
            });
            this.setState({
                allForms: formList,
                searchLoader: false
            }, () => { this.optionFormLoad() });
        }).catch(err => console.log(err))
    }

    optionFormLoad() {
        if (!this.state.allForms || this.state.allForms.length === 0) {
            return (<Option key={0} value={0}><span>No Result Found</span></Option>)
        }
        return this.state.allForms.map((obj, i) => {
            return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
                )
        }, this);
    }

    searchTestResult(e) {
        const value = e.target.value;
        const tempArrList = this.state.ExistingOrdersListedOriginal;

        if (value.length === 0) {
            this.setState({ searchTestValue: '' }, () => {
                this.setState({ ExistingOrdersListed: tempArrList });
            });
            return;
        }
        this.setState({ searchTestValue: value }, () => {
            const ExistingOrdersListedArr = tempArrList.filter((result) => {
            if ((result.service_name && result.service_name.toLowerCase().includes(this.state.searchTestValue.toLowerCase()))) {
                return result;
            }
            });
            this.setState({ ExistingOrdersListed: ExistingOrdersListedArr });
        });
    }

    specialityList = async () => {
        const PARAMS = {
            active: 1,
            facilityId: LS_SERVICE.get("FACILITY_ID"),
            enterpriseid:LS_SERVICE.get("ENTERPRISE_ID")
        }
        await Axios.post(OPD_SPECIALITY, PARAMS)
            .then(success => {
            let admittingSpecilialityData = success.data.data.filter((v, i) => v.id == parseInt(LS_SERVICE.get('speciality_id')));
            if(admittingSpecilialityData.length > 0)
            {
                this.setState({
                    defaultDoctorSpeciality : admittingSpecilialityData[0].description,
                })
            }
            this.setState({ specialityListData: success.data.data});
        }).catch(err => console.log(err))
    }

    getSunEditorInstance = async (instance) => {
        this.editorRef = instance
    }

    onEditorInput = async (evt) => {
        console.log("evt on input", evt);
        // let evtText = evt.editor.getData();
        let appendedText = evt.target.innerHTML.replace(/\n/g, "").replace(/\>[\t ]+\</g, "><");
        this.setState({ template: appendedText });
    }    
    onEditorChange = async (evt) => {
        let initialText = evt.replace(/\n/g, "").replace(/\>[\t ]+\</g, "><");
        this.setState({ template: initialText });
    }

    onChangeSpeciality = async (val, props) => {
        let specialityItems = [];
        let specialityNameItems = [];
        if (props && props.length) {
            props.map((obj, i) => {
                specialityItems.push(obj.value);
                specialityNameItems.push(obj.label);
            });
        }
        await this.setState({ speciality_selected: specialityItems, selected_specialities : specialityNameItems });
        this.caseSummaryForm.current.setFieldsValue({ speciality_selected: this.state.speciality_selected });
    }

    optionSpecialityLoad() {
        if (!this.state.specialityListData || this.state.specialityListData.length === 0) {
            return (<Option key={0} value={0}><span>No Result Found</span></Option>)
        }
        return this.state.specialityListData.map((obj, i) => {
            return (<Option key={i} disabled={(obj.id ==  LS_SERVICE.get('speciality_id')) ? true : false} value={obj.id} label={obj.description}>{obj.description}</Option>
            )
        }, this);
    }

    render() {
        const { 
            form_id,
            patient,
            template,
            initialSearchText,
            initialDocSearchText,
            initialSpecSearchText,
            isFormsButton,
            isVitalButton,
            isResultButton,
            isKeyFindingButton,
            isDischargeSummaryButton,
            isGraphButton,
            initialPatientFormText,
            allPatientFormsList,
            patientSelectedForm,
            allCreatedFormsFields,
            formSectionsId,
            ResultSectionsId,
            patientGraphList,
            graphSelectedKeys,
            isAllGraphChecked,
            docFormId,
            patientFormActive,
            is_authorized,
            userId,
            timelineData,
            selectedResultData,
            specialityListData,
            speciality_selected,
            searchLoader
        } = this.state;
        return (
            <>
            <Asideleft />
            <div className="content-wrapper">
                <div className="container-fluid emrfrm ">
                    { searchLoader == false ? (
                        <>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="float-left">
                                    <h1 className="page-title">Case Summary</h1>
                                </div>
                            </div>
                        </div>
                        <Form
                            ref={this.caseSummaryForm}
                            layout="vertical">
                            <div className="row mt-4">
                                <div className="col-4">
                                    <div className={(this.state.initialSearchText) ? "antSelectItem hasdata" : "antSelectItem"}>
                                            <Form.Item name="initialSearchText" label="Form Name"
                                            >
                                            <Select
                                                showSearch
                                                value={initialSearchText}
                                                notFoundContent={null}
                                                filterOption={false}
                                                onChange={this.onFormSelect}
                                                onSearch={(e) => this.onSearch(e)}
                                                style={{ width: '100%' }}
                                                filterOption={(input, option) => {
                                                return (
                                                option.children
                                                .toString()
                                                .toLowerCase()
                                                .indexOf(input.toString().toLowerCase()) >= 0
                                                );
                                                }}
                                                >
                                                {this.optionFormLoad()}
                                            </Select>
                                            </Form.Item>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className={(this.state.speciality_selected) ? "antSelectItem hasdata" : "antSelectItem"}>
                                        <Form.Item name="speciality_selected" label="Specialities"
                                        >
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            value={speciality_selected}
                                            notFoundContent={null}
                                            filterOption={false}
                                            onChange={this.onChangeSpeciality}
                                            style={{ width: '100%' }}
                                            disabled={(form_id != "") ? false: true}
                                            filterOption={(input, option) => {
                                            return (
                                            option.children
                                            .toString()
                                            .toLowerCase()
                                            .indexOf(input.toString().toLowerCase()) >= 0
                                            );
                                            }}
                                            >
                                            {this.optionSpecialityLoad()}
                                        </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-4 text-right ml-auto">
                                    {
                                        form_id && form_id !== "" && timelineData && timelineData.length > 0 ?
                                            <Tooltip placement="top" title='Timeline'>
                                                <button className="text-danger btn nostyle-link"
                                                    onClick={() => { this.handleTimeline(form_id) }}
                                                >
                                                    <FontAwesomeIcon icon={faEye} style={{ fontSize: '14px', color: 'black' }} />
                                                </button>
                                            </Tooltip>
                                            : null
                                    }
                                    { /* (form_id && form_id !== "") ?
                                        <button
                                            onClick={(e) => this.onRelaodTemplate({ value: form_id , speciality_selected : speciality_selected })}
                                            className={(is_authorized) ? "btn btn-primary" : "btn btn-outline-primary"}
                                            >REFRESH DATA
                                        </button>
                                    : null */} 
                                </div>
                            </div>
                        </Form>
                        
                        <div className="row">
                            <div className="col-10 mb-3">
                                <div className="form-group hasdata normal micwrap">
                                    <SunEditor getSunEditorInstance={(e) => this.getSunEditorInstance(e)}
                                    name="note"
                                    setOptions={{
                                    minHeight: "600",
                                    buttonList: [
                                    ['undo', 'redo', 'removeFormat', 'align', 'font', 'fontSize', 'fontColor'],
                                    ['hiliteColor', 'bold', 'underline', 'italic', 'strike', 'subscript', 'codeView']
                                    ],
                                    stickyToolbar: -1
                                    }}
                                    setDefaultStyle="font-family: arial; font-size: 14px;"
                                    autoFocus={false}
                                    setContents={template}
                                    disabled={(form_id != "") ? false: true}
                                    onChange={(e) => this.onEditorChange(e)}
                                    onInput={(e) => this.onEditorInput(e)} />
                                </div>
                            </div>
                            <div className="col-2 side_buttons_editor">
                                <div className="row">
                                    <div className="col-12">
                                        <button
                                        className={!isVitalButton ? 'btn btn-primary vitals hide_element' : 'btn btn-primary vitals'}
                                        disabled={(!isVitalButton || template == "")}
                                        onClick={() => this.handleShowModal('vitals')}
                                        >
                                        + VITALS
                                        </button>
                                        <button
                                        className={!isFormsButton ? 'btn btn-primary form hide_element' : 'btn btn-primary form'}
                                        disabled={(!isFormsButton || template == "")}
                                        onClick={() => this.handleShowModal('forms')}
                                        >
                                        + FORM
                                        </button>
                                        <button
                                        className={!isResultButton ? 'btn btn-primary result hide_element' : 'btn btn-primary result'}
                                        disabled={(!isResultButton || template == "")}
                                        onClick={() => this.handleShowModal('results')}
                                        >
                                        + RESULT
                                        </button>
                                        <button
                                        className={!isKeyFindingButton ? 'btn btn-outline-secondary viewkey hide_element' : 'btn btn-outline-secondary viewkey'}
                                        disabled={(!isKeyFindingButton || template == "")}
                                        onClick={() => this.getKeyFindingNew()}
                                        >
                                        VIEW KEY FINDINGS
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2 pb-4'>
                            <div className='col-12 '>
                                <div className='row'>
                                    <div className="w-100 d-flex justify-content-end">
                                        <button id="print_case_summary"
                                        title="Print"
                                        onClick={() => this.printPDFDocument()}
                                        disabled={is_authorized}
                                        className="btn btn-primary mr-3">PRINT</button>
                                        <button id="save_case_summary" onClick={() => this.saveTemplate(0)} type="submit"
                                        className="btn btn-primary mr-3">SAVE</button>
                                    </div>                      
                                </div>
                            </div>
                        </div>

                        {/* Modal View Timeline*/}
                        <Modal
                            centered
                            show={this.state.viewModal}
                            onHide={() => this.handleModalCancel()}
                            style={{ cursor: 'auto' }}
                            size={'lg'}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title className="ml-3">Case Summary Timeline</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="m-3">
                                <Form
                                    layout="vertical"
                                    className="emrfrm">
                                    <div className="row">
                                        <div className="col-md-12 col-xs-12" >
                                            <div className="row pb-5">
                                                
                                                <div className="col-12 mt-2">
                                                    <ul className="d_timeline">
                                                        {this.timeLineListLoad()}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>

                        {/* || ALL NEW KEY FINDINGS MODAL */}
                        <AntModal
                          title={[
                            <div className="mb-2">
                              <h6 className="float-lefts">Key Findings</h6>
                            </div>
                          ]}
                          visible={this.state.keyFindingsNewViewModal}
                          onCancel={(e) => this.handleCancelModalChartPopup(e, "keyFindingsNewViewModal")}
                          style={{ top: 40 }}
                          width="1080px"
                          bodyStyle={{ paddingBottom: "10px", maxHeight: "900px", paddingTop: '10px' }}
                          footer=''
                        >
                        <div className="row">
                            <div
                              className="col-12 d-flex justify-content-center mb-4" id="queue-top-navigation-bar"
                            >
                                <ul className="chart_filter">
                                    <li>
                                      <button
                                        type="button"
                                        className={`btn btn-default chart-day ${this.state.keyFindingsChartsTab === "0" ? "active" : ""}`}
                                        onClick={() => this.handleClickDuration('day', '0')}
                                      >
                                        DAY
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        className={`btn btn-default chart-week ${this.state.keyFindingsChartsTab === "1" ? "active" : ""}`}
                                        onClick={() => this.handleClickDuration('week', '1')}
                                      >
                                        WEEK
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        className={`btn btn-default chart-month ${this.state.keyFindingsChartsTab === "2" ? "active" : ""}`}
                                        onClick={() => this.handleClickDuration('month', '2')}
                                      >
                                        MONTH
                                      </button>
                                    </li>
                                    <li>
                                      <button
                                        type="button"
                                        className={`btn btn-default chart-year ${this.state.keyFindingsChartsTab === "3" ? "active" : ""}`}
                                        onClick={() => this.handleClickDuration('year', '3')}
                                      >
                                        YEAR
                                      </button>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-12 mb-2">
                                {
                                    this.state.isKeyFindingsLoaded ?
                                        <div className="card rounded-0 cardbg">
                                            <KeyFindingsNew
                                              keyFindingsNewDataFormatted={this.state.keyFindingsNewDataFormatted}
                                              handleModalPopupStateLift={this.handleModalChartPopup}
                                              handleKFDataCopyToTemplate={()=>{}}
                                              kfDataToCopyObj={{}}
                                              pageFrom='CaseSummary'
                                            />
                                        </div>
                                    :
                                    <div className='text-center'>{LOADER_RED}
                                    </div>
                                }
                            </div>
                          </div>
                        </AntModal>

                        <Modal
                            centered
                            show={this.state.resultModal}
                            onHide={() => this.handleResultModalCancel()}
                            style={{ cursor: 'auto' }}
                            size={'xxl'}
                            className="xlarge-modal-custom"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title className="ml-3">Result</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="m-3">
                                <Form
                                    layout="vertical"
                                    name="existingResults"
                                    className="emrfrm"
                                    onFinish={(e) =>
                                        this.existingResultsSave(e)
                                    }
                                    onFinishFailed={this.onResultFinishFailed.bind()}
                                    autoComplete="off">
                                    <div className='row mt-4'>
                                        <div className="col-6" >
                                            <div className={this.state.searchTestValue ? "antInputItem hasdata position-relative" : "antInputItem position-relative"}>
                                                <Form.Item
                                                    name="searchtest"
                                                    label="Search Test/Procedure"
                                                    id="searchtest"

                                                >
                                                    <Input
                                                    onChange={this.searchTestResult}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    maxLength={100}
                                                    name="searchTestValue"
                                                    defaultValue={this.state.searchTestValue}
                                                    value={this.state.searchTestValue}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        { Object.entries(selectedResultData).length > 0 ?
                                            <div className='col-6'>
                                                <div className='row pt-1'>
                                                    <div className='col-4 font-weight-bold'>{selectedResultData.component_name}</div>
                                                    <div className='col-auto  font-weight-bold'>{selectedResultData.test_status}</div>
                                                    <div className='col text-right'>
                                                        <span>Order Date :</span>
                                                        <span className='font-weight-bold'>{moment(selectedResultData.serviceorder_date).add('19800','seconds').format('DD MMM, YYYY | hh:mm A')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-6'>
                                            <div className='row '>
                                                <div className='col-12'>
                                                    <table className="table table-bordered" >
                                                        <thead>
                                                            <th>ORDER DATE & TIME</th>
                                                            <th>ORDERING LOCATION</th>
                                                            <th>ORDERED BY</th>
                                                            <th>ORDER NAME</th>
                                                            <th>STATUS</th>
                                                        </thead>
                                                        <tbody >
                                                            {this.ExistingOrdersListLoad()}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        { Object.entries(selectedResultData).length > 0 ?
                                            <div className='col-6'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <table className="table table-bordered " >
                                                            <thead>
                                                            {(selectedResultData.test_type !== null && selectedResultData.result_value_type.toUpperCase() == 'TEXT') ?
                                                                <tr>
                                                                    <th width="30%">
                                                                        TEST NAME
                                                                    </th>
                                                                    <th width="20%">RESULT</th>
                                                                    <th width="20%">UNIT</th>
                                                                    <th width="30%">REFERENCE INTERVAL</th>
                                                                </tr>
                                                                : ""}
                                                            </thead>
                                                            <tbody >
                                                                {
                                                                    selectedResultData.departmentType === "RIS" ?
                                                                    (
                                                                        <object width="100%" height="500" data={selectedResultData.result_link} type="application/pdf"> </object>
                                                                    )
                                                                    :
                                                                    selectedResultData.result_value_type.toUpperCase() == 'TEXT' ?
                                                                        selectedResultData.test_type === "GRP" ?
                                                                            selectedResultData.ordDtl.map((b,index) =>
                                                                            (<tr key={"result" + index}>
                                                                                <td key={index}>
                                                                                    <div className="custom-control custom-checkbox">
                                                                                        <input onChange={(e) => {
                                                                                            this.insertResultHtml({
                                                                                            target: {
                                                                                                id: b.order_component_id,
                                                                                                resultName: selectedResultData.component_name,
                                                                                                resultDate : moment(selectedResultData.serviceorder_date).add('19800','seconds').format('DD MMM, YYYY'),
                                                                                                text: (b.component_name && b.component_name != "" ? b.component_name + ": " : null) + b.test_value + ' ' + b.uomCode,
                                                                                                checked: e.target.checked
                                                                                            }
                                                                                        });
                                                                                        }} type="checkbox" checked={(ResultSectionsId && ResultSectionsId.indexOf(b.order_component_id) != -1) ? true : false} className="custom-control-input" id={"customCheck" + index} value={(b.component_name && b.component_name != "" ? b.component_name + ": " : null)} />
                                                                                        <label className="custom-control-label" htmlFor={"customCheck" + index}>{(b.component_name && b.component_name != "" ? b.component_name : null)}</label>
                                                                                    </div>
                                                                                </td>
                                                                                <td align="right" className={b.isAbnormal === "P" ? "text-danger" : b.isAbnormal === "N" ? "text-success" : "text-dark font-weight-medium"}>{b.isAbnormal === "P" ? (<u>{b.test_value}</u>) : b.test_value}</td>
                                                                                <td> {b.uomCode}</td>
                                                                                <td style={{ whiteSpace: "pre" }}>{b.reference_range.replaceAll("<br/>", "<br>").split("<br>").join("\r\n")}</td>
                                                                            </tr>)) :
                                                                            selectedResultData.test_type !== null ?
                                                                                <tr key={"result" + selectedResultData.serviceorder_id}>
                                                                                    <td key={selectedResultData.serviceorder_id}>
                                                                                        <div className="custom-control custom-checkbox">
                                                                                            <input onChange={(e) => {
                                                                                                this.insertResultHtml({
                                                                                                    target: {
                                                                                                        id: selectedResultData.serviceorder_id,
                                                                                                        resultName: selectedResultData.component_name,
                                                                                                        resultDate : moment(selectedResultData.serviceorder_date).add('19800','seconds').format('DD MMM, YYYY'),
                                                                                                        text: selectedResultData.test_value + ' ' + selectedResultData.uomCode,
                                                                                                        checked: e.target.checked
                                                                                                    }
                                                                                                });
                                                                                            }} type="checkbox" checked={(ResultSectionsId && ResultSectionsId.indexOf(selectedResultData.serviceorder_id) != -1) ? true : false} className="custom-control-input" id={"customCheck" + selectedResultData.serviceorder_id} value={(selectedResultData.component_name && selectedResultData.component_name != "" ? selectedResultData.component_name + ": " : null)} />
                                                                                            <label className="custom-control-label" htmlFor={"customCheck" + selectedResultData.serviceorder_id}>{(selectedResultData.component_name && selectedResultData.component_name != "" ? selectedResultData.component_name : null)}</label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className={selectedResultData.isAbnormal.toUpperCase() === "P" ? "text-danger" : selectedResultData.isAbnormal.toUpperCase() === "N" ? "text-success" : "text-dark font-weight-medium"}>{selectedResultData.isAbnormal.toUpperCase() === "P" ? (<u>{selectedResultData.test_value}</u>) : selectedResultData.test_value}</td>
                                                                                    <td> {selectedResultData.uomCode}</td>
                                                                                    <td style={{ whiteSpace: "pre" }}> {selectedResultData.reference_range.replaceAll("<br/>", "<br>").split("<br>").join("\r\n")}</td>
                                                                                </tr>
                                                                                : <></>
                                                                        : (<object width="100%" height="500" data={selectedResultData.result_link} type="application/pdf"> </object>)

                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className="col-12 text-right" >
                                                        <Button type="primary" htmlType="submit" className="btn btn-primary">
                                                            ADD TO CASE SUMMARY
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    
                                </Form>
                            </Modal.Body>
                        </Modal>

                        {/* Forms Modal Starts here */}
                        <Modal show={this.state.show == 'forms'} onHide={this.handleCloseModal} centered
                        style={{ cursor: 'auto' }}
                        size={'xxl'}
                        className="xlarge-modal-custom">
                            <Modal.Header closeButton className="pl-3">
                                <Modal.Title>Existing Forms </Modal.Title>
                                {this.state.modalLoader ?
                                    <div className="text-center">{this.state.modalLoader ? LOADER_RED : null}</div>
                                    : null}
                            </Modal.Header>
                            <Modal.Body className="m-3">
                                <div className="modal-content" id="form-modal">
                                    <Form
                                        name="existingForm"
                                        className="emrfrm"
                                        layout="vertical"
                                        onFinish={(e) =>
                                            this.existingFormSave(e)
                                        }
                                        onFinishFailed={this.onFormFinishFailed.bind(this)}
                                        autoComplete="off">
                                        <div className="modal-body ml-3 mr-3">
                                            <div className="row mt-4">
                                                <div className="col-6" >
                                                    <div className="antSelectItem hasdata">
                                                        <Form.Item
                                                        name="formid"
                                                        id="formid"
                                                        label="Form"
                                                        >
                                                            <Select
                                                                showSearch
                                                                notFoundContent={null}
                                                                filterOption={false}
                                                                onChange={this.onPatientFormChange}
                                                                value={this.state.formSelected}
                                                                style={{ width: '100%' }}
                                                                filterOption={(input, option) => {
                                                                return (
                                                                option.children
                                                                .toString()
                                                                .toLowerCase()
                                                                .indexOf(input.toString().toLowerCase()) >= 0
                                                                );
                                                                }}
                                                            >
                                                                {this.optionPatientFormLoad()}
                                                            </Select>
                                                        
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-6" >
                                                    <button role="button" type="button" onClick={this.resetPatientForm.bind(this)} className="btn btn-primary">RESET</button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-5" style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                                                    <table className="table table-bordered rounded-0 position-sticky">
                                                        <thead>
                                                            <tr>
                                                                <th>Date</th>
                                                                <th>Form Name</th>
                                                                <th>Signed By</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(allPatientFormsList && allPatientFormsList.length) ?
                                                                allPatientFormsList.map((data, k) => (
                                                                    <tr key={"plist" + k}>
                                                                        <td><Moment parse="YYYY-MM-DDTHH:mm:ss" date={data.enteredDate} format="DD MMM, YYYY h:mm A" /></td>
                                                                        <td role="button" onClick={(e) => {
                                                                            this.getPateintFormFieldsData({
                                                                                target: {
                                                                                    id: data.id,
                                                                                    formId: data.formId,
                                                                                    formName: data.formName
                                                                                }
                                                                            });
                                                                        }} className={patientFormActive && docFormId === data.id ? 'bg-primary text-white' : null}>{data.formName}</td>
                                                                        <td className={data.statusValue == "Authorized" ? null : 'text-primary'}>
                                                                            {data.statusValue == "Authorized" ? data.doctorName : "In Progress"}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                : <tr><td >No Records Found</td></tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-7" style={{ maxHeight: 'calc(100vh - 300px)', overflowY: 'auto' }}>
                                                    <table className="table rounded-0 position-sticky">
                                                        <thead>
                                                            <tr>
                                                                {(patientSelectedForm && patientSelectedForm !== "" ?
                                                                    <th>{patientSelectedForm}</th>
                                                                    :
                                                                    <th>&nbsp;</th>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(allCreatedFormsFields && allCreatedFormsFields.length) ?
                                                                allCreatedFormsFields.map((fieldData, i) => (
                                                                    <tr key={"form" + i}>
                                                                        <td key={i}>
                                                                            <div className="custom-control custom-checkbox">
                                                                                <input onChange={(e) => {
                                                                                    this.insertHtml({
                                                                                        target: {
                                                                                            id: fieldData.id,
                                                                                            formName: fieldData.formName,
                                                                                            text: (fieldData.title && fieldData.title != "" ? fieldData.title + ": " : null) + fieldData.value,
                                                                                            checked: e.target.checked
                                                                                        }
                                                                                    });
                                                                                }} type="checkbox" checked={(formSectionsId && formSectionsId.indexOf(fieldData.id) != -1) ? true : false} className="custom-control-input" id={"customCheck" + i} value={(fieldData.title && fieldData.title != "" ? fieldData.title + ": " : null) + fieldData.value} />
                                                                                <label className="custom-control-label" htmlFor={"customCheck" + i}>{(fieldData.title && fieldData.title != "" ? fieldData.title + ": " : null)} <span className="font-weight-medium" dangerouslySetInnerHTML={{ __html: fieldData.value }}></span></label>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                                : <tr><td>Please select form name from left.</td></tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" onClick={this.handleCloseModal} className="btn btn-outline-secondary mr-3">CANCEL</button>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" className="btn btn-primary">
                                                    ADD TO CASE SUMMARY
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </div>
                            </Modal.Body>
                        </Modal>
                        {/* Forms Modal Ends here */}

                        {/* Vitals Modal */}
                        <AntModal
                            title={"Vital Charting"}
                            visible={this.state.show == 'vitals'}
                            onHide={this.handleCloseModal}
                            onCancel={this.handleCloseModal}
                            destroyOnClose={true}
                            footer={false}
                            // width={1200}
                            className="xlarge-modal-custom"
                            >
                            <IPDVitalCharting pageFrom='CS' handleCaseSheetSummaryVitals={this.handleCaseSheetSummaryVitals} mhea_patient_id={this.state.patient.mhea_patient_id}></IPDVitalCharting>
                        </AntModal>

                        {/* || KEY FINDINGS CHART MODAL New */}
                        {this.state.keyFindingChartModalNew ? (
                            <AntModal
                                destroyOnClose={true}
                                // title={`${this.state.chartsType === 'all' ? 'Vital' : this.state.chartsType} Graphs`}
                                title={this.state.keyFindingsChartHeading}
                                visible={this.state.keyFindingChartModalNew}
                                onCancel={(e) => this.handleCancelChartPopup()}
                                style={{
                                  top: "40px",
                                  maxWidth: this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding-group" ? "90%" : "600px",
                                }}
                                className={`${this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding-group" ? "all_vital" : "single_vital"
                                  }`}
                                width="100%"
                                bodyStyle={{ maxHeight: "650px", overflowY: "auto" }}
                                footer={
                                  this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding_group" ? (
                                    <div className="text-center justify-content-center">
                                      <button
                                        type="submit"
                                        className="btn btn-primary text-uppercase ml-2"
                                        onClick={(e) => this.handleModalChartPopup(e, "keyFindingChartModalNew")}
                                      >
                                        Confirm
                                      </button>
                                    </div>
                                  ) : null
                                }
                            >
                                <KeyFindingsChartNew
                                  data={this.state.kfChartData}
                                  chartType={this.state.keyFindingsChartsType}
                                  chartHeading={this.state.keyFindingsChartHeading}
                                />
                            </AntModal>
                          ) : null
                        }
                        </>
                    ) : (
                        <div className="w-100 align-items-center justify-content-center mt-4 d-flex">
                        {LOADER_RED}
                        </div>
                    )}
                </div>
            </div>
            </>
        )
    }
}