import React, { Component, Fragment } from 'react';
import axios from 'axios';
import LS_SERVICE from '../../utils/localStorage';
import { LOADER_RED, PATIENT_DATA, PRESCRIPTION_DATA, MAR_DATA, STORE_LIST, BATCH_LIST, SAVE_MAR_GIVENOW } from '../../utils/constant';
import moment from "moment";
import AsideLeft from '../../components/aside/asideleft';
import { Button, Pagination, Modal, Form, Input, notification, Select, DatePicker } from 'antd';
import { headerService$, practiceService } from '../../utils/rxjs-sharing';
const { Option } = Select;
class MAR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DataLoaded: false,
            patient: null,
            constants: [],
            currentPage: 1,
            countPage: '',
            hospital_id: null,
            drugDailyFrequency: [],
            drugDurationFrequency: [],
            drugsData: [],
            giveNowModal: false,
            submitButtonClicked: false,
            current_drug_id: null,
            current_drug: null,
            storeList: [],
            batchList: [],
        };
        this.givenowFormRef = React.createRef();
        this.navigateToPage = this.navigateToPage.bind(this);
    }

    async componentDidMount() {
        const { match: { params } } = this.props;
        const { hospital_id, patient_id, reference_id } = params;

        if (this.props.location?.state?.patient) {
            this.setState({ patientData: this.props.location.state.patient });
        } else {
            this.getPatientData(reference_id);
        }

        const patient = {
            hospital_id,
            patient_id,
            qms_token_id: reference_id
        }
        let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
        hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);

        this.setState({ hospital_id, patient_id, patient }, () => {
            if (LS_SERVICE.has('hospitalConfigData')) {
                const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
                let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
                headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout ? hospitalConfigObj.opd_layout : 'soap' });
            }
            this.getDrugsList();
        });
    }

    getPatientData = async (reference_id) => {
        var reference_type = await LS_SERVICE.get('reference_type') || 0;
        await axios.get(PATIENT_DATA({ reference_id, reference_type })).then(success => {
            const patient = {
                ...success.data.data.results,
                qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id
            }
            this.setState({ patientData: patient });
        })
    }

    getDrugsList = async () => {
        // const { patient, currentPage } = this.state;
        const { patient_id, qms_token_id } = this.state.patient;
        const drugsList = await axios.get(PRESCRIPTION_DATA({
            hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' && LS_SERVICE.get('slot_hospital_id') ? LS_SERVICE.get('slot_hospital_id') : null,
            patient_id,
            reference_type: LS_SERVICE.get('reference_type'),
            reference_id: qms_token_id,
            store_id: LS_SERVICE.get("STORE_ID"),
            facility_id: LS_SERVICE.get("FACILITY_ID"),
            enterprise_id: LS_SERVICE.get("ENTERPRISE_ID")
        }));

        const marDrugsList = await axios.post(MAR_DATA, {
            reference_type: parseInt(LS_SERVICE.get('reference_type')),
            reference_id: parseInt(qms_token_id),
        });

        var constants = drugsList?.data?.data?.constants
        var details = drugsList?.data?.data?.details || [];
        var unAdministeredDrugs = [];
        if(details?.length > 0){
            // only STAT records
            let drugsDataFiltered = details.map(item => { if (!item.mar && (item.urgency && item.urgency == 51)) return item; });
            unAdministeredDrugs = drugsDataFiltered.filter(function (element) {
                return element !== undefined;
            });
        }
        var administeredDrugs = [];
        if(marDrugsList?.data?.mar?.length > 0){
            administeredDrugs = marDrugsList?.data?.mar
        }
        var drugsData = administeredDrugs.concat(unAdministeredDrugs)
        this.setState({ constants, drugsData, DataLoaded: true });
    }

    navigateToPage(page) {
        this.setState({ currentPage: page }, () => {
            this.getDrugsList();
        })
    }

    optionRouteLoad() {
        if (this.state.drugRoute && this.state.drugRoute.length > 0) {
            return this.state.constants.route.map((obj, i) => {
                this.state.drugRoute[obj.id] = obj.description;
            }, this)
        } else {
            return null;
        }
    }

    // optionDaysLoad() {
    //     return this.state.constants.daysInWeek.map((obj, i) => {
    //         this.state.drugDaysInWeek[obj.id] = obj.description;
    //     }, this)
    // }

    // optionSchedulesLoad() {
    //     this.schedulesName = [];
    //     return this.state.constants.drugSchedules.map((obj, i) => {
    //         this.state.drugSchedules[obj.id] = obj.description;
    //         this.schedulesName.push(obj.description)
    //     }, this)
    // }

    // optionPriorityLoad() {
    //     return this.state.constants.drugUrgency.map((obj, i) => {
    //         this.state.drugUrgency[obj.id] = obj.description;
    //     }, this)
    // }

    // optionInstructionLoad() {
    //     return this.state.constants.drugInstructions.map((obj, i) => {
    //         this.state.drugInstructions[obj.id] = obj.description;
    //     }, this)
    // }

    // optionFormDrugLoad() {
    //     if (!this.state.constants.drugForm || this.state.constants.drugForm.length === 0) {
    //         return (<Option value={0}><span>No Result Found</span></Option>)
    //     }
    //     return this.state.constants.drugForm.map((obj, i) => {
    //         this.state.drugForm[obj.id] = obj.description;
    //         return (<Option key={i} value={obj.id} title={obj.description}>{obj.description}</Option>
    //         )
    //     }, this)
    // }

    // optionDosageLoad() {
    //     if (this.state.constants.drugDosage && this.state.constants.drugDosage.length > 0) {
    //         return this.state.constants.drugDosage.map((obj, i) => {
    //             this.state.drugDosage[obj.id] = obj.description;
    //         }, this)
    //     } else {
    //         return null;
    //     }
    // }

    optionDailyFrequencyLoad() {
        if (this.state.constants.dailyFrequency && this.state.constants.dailyFrequency.length) {
            return this.state.constants.dailyFrequency.map((obj, i) => {
                this.state.drugDailyFrequency[obj.id] = obj.description;
            }, this);
        } else {
            return null;
        }
    }

    optionDurationFrequencyLoad() {
        if (this.state.constants.dailyFrequency && this.state.constants.dailyFrequency.length) {
            return this.state.constants.dailyFrequency.map((obj, i) => {
                this.state.drugDurationFrequency[obj.id] = obj.interval_per;
            }, this);
        } else {
            return null;
        }
    }

    getStoreList = async () => {
        try {
            const PARAMS = {
                getStoreList: LS_SERVICE.get('staff_id'),
                facilityId: LS_SERVICE.get('FACILITY_ID'),
                storeId: 0,
                transactionCode: 'IPISS',
            }
            var response = await axios.post(STORE_LIST, PARAMS);
            if (response?.status && response?.data?.data?.length) {
                this.setState({ storeList: response?.data?.data });
            }
        } catch (error) {
            console.log('error-get-store-list:', error);
        }
    }

    getBetchNumber = async (storeId) => {
        try {
            this.givenowFormRef.current.resetFields(['batch_no']);
            const PARAMS = {
                facilityId: LS_SERVICE.get('FACILITY_ID'),
                itemId: this.state.current_drug_id,
                enterpriseId: LS_SERVICE.get('ENTERPRISE_ID'),
                storeId: +storeId
            }
            var response = await axios.post(BATCH_LIST, PARAMS);
            if (response?.status && response?.data?.data?.length) {
                this.setState({ batchList: response?.data?.data });
            }
            else{
                this.setState({ batchList: [] });
            }
        } catch (error) {
            console.log('error-get-batch-no:', error);
        }
    }

    handleModalPopup = (e, popup, data = null, allDrugData) => {
        this.getStoreList();
        this.setState({
            [popup]: !this.state[popup],
            current_drug_id: data,
            current_drug: allDrugData
        }, () => {
            // code here
        })
    }

    MARRowLoad() {
        // Group the data by service_name
        const groupedData = this.state.drugsData.reduce((acc, row) => {
            acc[row.drug_id] = acc[row.drug_id] || [];
            acc[row.drug_id].push(row);
            return acc;
        }, {});

        return (
            <table className="table table-bordered custom-plan-header mb-0 mt-1">
                <thead>
                    <tr>
                        <th width="4%">S.NO</th>
                        <th width="30%">MEDICINE NAME</th>
                        <th width="10%">STRENGTH</th>
                        <th width="10%">DOSE</th>
                        <th width="14%">FREQUENCY</th>
                        <th width="10%">ROUTE</th>
                        <th width="10%">DURATION</th>
                        <th width="12%" style={{ textAlign: "center" }}>Action</th>
                    </tr>
                </thead>
                <tbody id="template-list">
                    {Object.keys(groupedData).length ? (
                        Object.keys(groupedData).map((itemKey, groupIndex) => {
                            const rows = groupedData[itemKey];
                            return rows.map((item, rowIndex) => (
                                <Fragment key={`${groupIndex}-${rowIndex}`}>
                                    <tr>
                                        {rowIndex === 0 && (
                                            <>
                                                <td rowSpan={item?.medicine_remarks ? rows.length+1 : rows.length} className={item?.medicine_remarks ? 'text-dark remarks-included' : 'text-dark'} style={{ fontWeight: 500 }}>
                                                    {groupIndex + 1}
                                                </td>
                                                <td rowSpan={item?.medicine_remarks ? null : rows.length} className={item?.medicine_remarks ? 'text-dark remarks-included remark-border' : 'text-dark tablet-border'} style={{ fontWeight: 500 }}>
                                                    {item?.drug_name}
                                                </td>
                                            </>
                                        )}
                                        <td>{item?.strength || "-"}</td>
                                        <td>{item?.dosage_value ? `${item?.dosage_value} ${item?.dosage_form_text || ''}` : '-'}</td>
                                        <td>{item?.days_in_week_text && item?.drug_frequency === 2
                                                ? `${this.state.drugDailyFrequency[item?.daily_frequency]} (${item?.days_in_week_text})`
                                                : item?.drug_frequency === 1
                                                ? this.state.drugDailyFrequency[item?.daily_frequency] || '-'
                                                : this.state.constants.drugFrequency[item?.drug_frequency]?.description === "PRN"
                                                ? "PRN/SOS"
                                                : this.state.constants.drugFrequency[item?.drug_frequency]?.description || '-'
                                            }
                                        </td>
                                        <td>{item?.route ? this.state.constants.route.find(data => data.id === item?.route)?.description : '-'}</td>
                                        <td>{item.number_of_days 
                                                ? item.number_of_days + " " + (item?.number_of_days_type !== null 
                                                    ? item?.number_of_days_type 
                                                    : this.state.drugDurationFrequency[item.daily_frequency]) 
                                                : (parseInt(item?.drug_frequency) != 3 
                                                    ? (parseInt(item?.daily_frequency) == 4 ? '-' : 'Till review') 
                                                    : '-')
                                            }
                                        </td>
                                        {rowIndex === 0 && (
                                            <td className="text-center" rowSpan={rows.length}>
                                                {item.administerted_by ? (
                                                    <>
                                                        <span><strong>Administered By:</strong> {item.administerted_by}</span><br />
                                                        <span>{moment(item.datetime_administration).add(330, 'minutes').format('DD MMM YYYY | hh:mm A')}</span><br />
                                                        <span><strong>Qty Used:</strong> {item.quantity}</span><br />
                                                        <span><strong>Remarks:</strong> {item.remarks}</span>
                                                    </>
                                                ) : (
                                                    <Button
                                                        danger
                                                        type="primary"
                                                        size="small"
                                                        style={{ padding: '2px 10px', fontSize: '13px', height: '27px', lineHeight: '22px' }}
                                                        onClick={(e) => this.handleModalPopup(e, 'giveNowModal', item.drug_id, item)}
                                                    >GIVE NOW</Button>
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                    {item.medicine_remarks && rowIndex === 0 && (
                                        <tr>
                                            <td colSpan="7" className="remarks-column">
                                                <span className="bold-500">Remarks:&nbsp;</span>
                                                <span>{item.medicine_remarks}</span>
                                            </td>
                                        </tr>
                                    )}
                                </Fragment>
                            ));
                        })
                    ) : (
                        <tr>
                            <td colSpan={8} className="text-center">No MAR Records</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    async cancelGiveNow() {
        this.setState({ submitButtonClicked: false, giveNowModal: false, current_drug_id: null, current_drug: null, batchList: [] });
        this.givenowFormRef.current.resetFields();
    }

    async handleSubmitGiveNow(formData) {
        const { patient } = this.state;
        try {
            this.setState({ submitButtonClicked: true });
            let postData = {
                store_id: +formData.store_id,
                drug_id: +this.state.current_drug_id,
                drug: this.state.current_drug,
                batch_no: String(formData.batch_no),
                datetime_administration: moment(formData.datetime_administration).utc().format('YYYY-MM-DD HH:mm:ss'),
                quantity: formData.quantity,
                remarks: formData.remarks,
                administerted_by: LS_SERVICE.get("profile")?.data?.full_name || "Dr. " + LS_SERVICE.get("staff_name") || null,
                reference_type: LS_SERVICE.get('reference_type') || 0,
                reference_id: patient.qms_token_id, 
            }
            var saveMarRes = await axios.post(SAVE_MAR_GIVENOW, postData);
            if (saveMarRes?.data?.status) {
                this.getDrugsList();
                notification.success({
                    message: saveMarRes?.data?.message,
                    placement: "topRight",
                });
                this.setState({ submitButtonClicked: false, giveNowModal: false, batchList: [], current_drug_id: null, current_drug: null });
                this.givenowFormRef.current.resetFields();
            }
            else {
                notification.error({
                    message: saveMarRes?.data?.message,
                    placement: "topRight",
                });
            }
        } catch (error) {
            notification.error({
                message: "Something went wrong!",
                placement: "topRight",
            });
        }
    }

    render() {
        // this.optionFormDrugLoad();
        // this.optionDosageLoad();
        // this.optionDaysLoad();
        // this.optionSchedulesLoad();
        // this.optionPriorityLoad();
        // this.optionInstructionLoad();
        this.optionRouteLoad();
        this.optionDailyFrequencyLoad();
        this.optionDurationFrequencyLoad();

        const { patient, drugsData, storeList, patientData } = this.state;
        const appointmentDate = patientData?.qmsToken?.Booking?.booking_date;
        const appointmentTime = patientData?.qmsToken?.Booking?.start_time;
        const appointmentDateTime = moment(`${appointmentDate} ${appointmentTime}`, "YYYY-MM-DD HH:mm:ss");
        
        return (
            <>
                {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}
                <div className="content-wrapper">
                    <div className="container-fluid emrfrm">

                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="float-left">
                                    <h1 className="page-title mb-0">MAR (Only for STAT Dose)</h1>
                                </div>
                            </div>
                        </div>

                        {this.state.DataLoaded ? (
                            <div className="row">
                                <div className="table-responsive emrfrm" id="order-set-tab">
                                    {this.MARRowLoad()}
                                </div>
                                <div className="col-12 mb-5">
                                    {(drugsData !== null && this.state.countPage > 10) ?
                                        <Pagination defaultCurrent={this.state.currentPage} total={this.state.countPage} onChange={this.navigateToPage} />
                                        : null}
                                </div>
                            </div>
                        ) :
                            <div className="row">
                                <div className="col-12 text-center mt-2">{LOADER_RED}</div>
                            </div>
                        }
                    </div>
                </div>

                {/* give now modal */}
                <Modal
                    title="Give Now"
                    onCancel={(e) => this.cancelGiveNow(e)}
                    visible={this.state.giveNowModal}
                    footer={false}
                    width={'45%'}
                >
                    <div>
                        <Form
                            layout="vertical"
                            autoComplete="off"
                            className='emrfrm'
                            onFinish={(e) => this.handleSubmitGiveNow(e)}
                            initialValues={{ remember: false }}
                            ref={this.givenowFormRef}
                        >
                            <div className='row mt-1'>
                                <div className="col-6">
                                    <div className="antSelectItem position-relative hasdata">
                                        <Form.Item
                                            name="store_id"
                                            label="Store"
                                            rules={[{ required: true, message: "Store is required", },]}
                                        >
                                            <Select
                                                showSearch
                                                allowClear
                                                filterOption={(input, option) => {
                                                    return (
                                                        option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                    );
                                                }}
                                                onChange={(e) => this.getBetchNumber(e)}
                                            >
                                                {storeList.length ?
                                                    this.state.storeList.map((obj, index) => (
                                                        <Option key={index} value={obj?.storeId}> {obj?.storeName}</Option>
                                                    ))
                                                    : null
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="antSelectItem position-relative hasdata">
                                        <Form.Item
                                            name="batch_no"
                                            label="Batch No"
                                            rules={[{ required: true, message: "Batch number is required" },]}
                                        >
                                            <Select
                                                className="select-diagnosis"
                                                autoFocus={false}
                                                showSearch
                                                allowClear
                                                filterOption={(input, option) => {
                                                    return (
                                                        option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                                                    );
                                                }}
                                            >
                                                {
                                                    this.state.batchList.map((obj, index) => (
                                                        // <Option key={index} value={obj?.id}>{obj?.batchNo}</Option>
                                                        <Option key={index} value={obj?.batchNo}>{obj?.batchNo}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-1'>
                                <div className="col-6">
                                    <div className="antSelectItem position-relative hasdata">
                                        <Form.Item
                                            name="datetime_administration"
                                            label="Date & Time of Administration"
                                            rules={[{ required: true, message: "Date & time of administration is required" }]}
                                            valuePropName={'datetime_administration'}
                                        >
                                            <DatePicker
                                                format="DD MMM YYYY | hh:mm A"
                                                showTime={{defaultValue: appointmentDateTime}}
                                                style={{
                                                    width: "100%",
                                                    height: "45px",
                                                }}
                                                // value={appointmentDateTime}
                                                disabledDate={(currentDate) => {return currentDate && currentDate.isBefore(moment(appointmentDate))}}
                                                disabledTime={(currentDate) => {
                                                    if (!currentDate || !appointmentDateTime || !currentDate.isSame(appointmentDateTime, "day")) {
                                                        return {}; 
                                                    }
                                            
                                                    const hour = appointmentDateTime.hour();
                                                    const minute = appointmentDateTime.minute();
                                            
                                                    return {
                                                        disabledHours: () => [...Array(hour).keys()],
                                                        disabledMinutes: (h) => (h === hour ? [...Array(minute + 1).keys()] : []),
                                                    };
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="antSelectItem position-relative hasdata">
                                        <Form.Item
                                            name="quantity"
                                            label="Quantity"
                                            rules={[{ required: true, message: "Please Select Quantity" },]}
                                        >
                                            <Form.Item
                                                name="quantity"
                                                label="Quantity"
                                                rules={[
                                                    { required: true },
                                                    { pattern: /[0-9\b.]/i, message: "Only number and decimal allowed" }
                                                ]}
                                            >
                                                <Input className="select-diagnosis" minLength={1} maxLength={5} />
                                            </Form.Item>
                                            {/* <Select allowClear >
                                                {new Array(10).fill(1).map((item, index) => (
                                                    <Option key={index + 1} value={index + 1}>{index + 1}</Option>
                                                ))}
                                            </Select> */}
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 mt-1'>
                                    <div className="form-group mb-0 filldata ">
                                        <Form.Item
                                            name="remarks"
                                            label="Remarks"
                                            rules={[
                                                { required: false },
                                                // { pattern: /^[a-z\s]+$/i, message: "Only characters allowed" }
                                            ]}
                                        >
                                            <Input className="select-diagnosis" minLength={3} maxLength={100} />
                                        </Form.Item>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className='col-12 text-center'>
                                    {/* <button disabled={this.state.submitButtonClicked} className='btn btn-outline-secondary mr-2' onClick={() => this.setState({ giveNowModal: false })}>Cancel</button> */}
                                    <button disabled={this.state.submitButtonClicked} type="submit" className='btn btn-primary ml-2'>Submit</button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal>
            </>
        )
    }
}

export default MAR;