import React, { Component } from "react";
import Axios from "axios";
import qs from "qs";
import $ from 'jquery';
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Modal, Carousel, Radio } from 'antd';
import Moment from 'react-moment';
import LS_SERVICE from '../../utils/localStorage';
import ImgIcon from "../../assets/images/ic-img.svg";
import {
  LOADER_RED,
  DIAGNOSIS_LIST_API,
  DIAGNOSIS_LIST_UPLOADS_API,
  DISCHARGE_SUMMARY_LIST_API,
  EMR_APP_ENV
} from "../../utils/constant";
import { Pagination } from "antd";
import AsideLeft from "../../components/aside/asideleft";
import moment from "moment";
class PatientDiagnosisReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'reports',
      currentPage: 1,
      pagination: null,
      patient: null,
      allResults: [],
      loading: false,
      dateFormat: 'YYYY-MM-DD',
      filterByValue: '3 Months',
      patientId: null,
      search_start_date: null,
      search_end_date: null,
      moment_start_date: null,
      moment_end_date: null,
      showRxModal: false,
      rxPdfPath: null,
      diagnosisType: null,
      viewDocumentModal: false,
      showCalender: false,
      documentData: [],
      diagnosisReport: false,
      dischargeReport: false,
      preset_ranges: {
        'Last 24 Hours': [moment().subtract(24, 'hours'), moment()],
        '1 Week': [moment().subtract(1, 'weeks'), moment()],
        '2 Weeks': [moment().subtract(2, 'weeks'), moment()],
        '3 Weeks': [moment().subtract(3, 'weeks'), moment()],
        '1 Month': [moment().subtract(1, 'month'), moment()],
        '2 Months': [moment().subtract(2, 'month'), moment()],
        '3 Months': [moment().subtract(3, 'month'), moment()],
        '1 Year': [moment().subtract(1, 'year'), moment()]
      },
      //dateFilter: '1 Year',
      requestSent: false,
      report_type : 1
    }
    this.tabChanged = this.tabChanged.bind(this);
    this.carouselRef = React.createRef();
  }

  goBack = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  }

  async componentDidMount() {
    const { match: { params } } = this.props;
    const { patient_id } = params;
    const patient = { patient_id };
    await this.setState(
      {
        patient_id,
        patient,
        diagnosisType: this.props.location?.state?.diagnosisType ? this.props.location.state.diagnosisType : false,
        diagnosisReport: this.props.location?.state?.diagnosisReport,
        dischargeReport: this.props.location?.state?.dischargeReport,
        currentTab: (this.props.location?.state?.dischargeReport) ? "iperreports" : "reports"
      });
    this.dateFilter = this.dateFilter.bind(this);
    this.navigateToPage = this.navigateToPage.bind(this);
    this.setInitialSearch();
  }

  setInitialSearch = async (tabSet) => {
    const start = moment().subtract(3, 'month').toObject();
    const end = moment().toObject();
    await this.setState({
      currentTab: this.state.currentTab ? this.state.currentTab : 'reports',
      search_start_date: new Date(start.years, start.months, start.date),
      search_end_date: new Date(end.years, end.months, end.date),
      moment_start_date: moment().subtract(3, 'month').format(this.state.dateFormat),
      moment_end_date: moment().format(this.state.dateFormat),
      currentPage: 1,
      filterByValue: '3 Months'
    });
    if (!tabSet) {
      this.searchResults();
    }
  }

  resetForm = async () => {
    $('.ranges>ul>li').removeClass('active');
    $('.ranges>ul>li').parent().addClass('main_ul');
    $('.ranges>ul>li').each(function () {
      var group = $('ul[class="main_ul"]');
      var data = $(this).attr('data-range-key');
      $("[data-range-key$='" + data + "']", group).attr('class', 'inactive_link');
    });
    var group = $('ul[class="main_ul"]');
    $('[data-range-key$="3 Months"]', group).attr('class', 'active_link');

    const start = moment().subtract(3, 'month').toObject();
    const end = moment().toObject();
    await this.setState({
      currentTab: this.state.currentTab ? this.state.currentTab : 'reports',
      search_start_date: new Date(start.years, start.months, start.date),
      search_end_date: new Date(end.years, end.months, end.date),
      moment_start_date: moment().subtract(3, 'month').format(this.state.dateFormat),
      moment_end_date: moment().format(this.state.dateFormat),
      currentPage: 1,
      filterByValue: '3 Months',
      showCalender: true
    });
    this.searchResults();
  }

  onloadReset = async () => {
    if(this.state.showCalender){
      $('.daterangepicker').removeClass('show-calendar');
    }
    $('.ranges>ul>li').removeClass('active');
    $('.ranges>ul>li').parent().addClass('main_ul');
  }

  dateFilter = async (start, end, label) => {
    const crlabel = start.format('LL') + ' - ' + end.format('LL');
    await this.setState({
      filterByValue: (label === 'Custom Range') ? crlabel : label,
      search_start_date: new Date(start.years, start.months, start.date),
      search_end_date: new Date(end.years, end.months, end.date),
      moment_start_date: start.format(this.state.dateFormat),
      moment_end_date: end.format(this.state.dateFormat)
    }, () => {
      if (this.state.filterByValue) {
        $('.ranges>ul>li').parent().addClass('main_ul');
        var group = $('ul[class="main_ul"]');
        $('[data-range-key$="Last 24 Hours"]', group).attr('class', 'inactive_link');
        $('.ranges>ul>li').each(function () {
          var group = $('ul[class="main_ul"]');
          var data = $(this).attr('data-range-key');
          $("[data-range-key$='" + data + "']", group).attr('class', 'inactive_link');
        });
        $("[data-range-key$='" + this.state.filterByValue + "']", group).attr('class', 'active_link');
      }
      this.searchResults();
    });
  }

  async searchResults() {
    const { patient, currentPage, currentTab, report_type } = this.state;
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    await this.setState({
      loading: true,
      pagination: null,
      requestSent: false,
      allResults: [],
    });
    const startdate = this.state.moment_start_date;
    const enddate = this.state.moment_end_date;
    if (currentTab && currentTab == 'reports') {
      const PARAMS = {
        patient_id: patient.patient_id,
        pagination: currentPage,
        startdate,
        enddate,
        type: currentTab,
        hospital_id: hospital_id,
        report_type : report_type
      };
      const results = await Axios.get(DIAGNOSIS_LIST_API(PARAMS));
      if (results?.data?.data?.testList?.reports && results?.data?.data?.testList?.reports.length > 0) {
        await this.setState({
          loading: false,
          requestSent: true,
          allResults: results.data.data.testList.reports,
          pagination: results.data.data.testList.pagination,
        });
      } else {
        await this.setState({
          loading: false
        });
      }
    } else if (currentTab && currentTab == 'uploads') {
      const PARAMS = {
        patient_id: patient.patient_id,
        pagination: currentPage,
        startdate,
        enddate,
        type: currentTab,
      };
      const results = await Axios.get(DIAGNOSIS_LIST_UPLOADS_API(PARAMS));
      if (results.data.data.testList.records && results.data.data.testList.records.length > 0) {
        await this.setState({
          loading: false,
          requestSent: true,
          allResults: results.data.data.testList.records,
          pagination: results.data.data.testList.pagination,
        });
      } else {
        await this.setState({
          loading: false
        });
      }
    }else if (currentTab && currentTab == 'iperreports') {
      const PARAMS = {
        patient_id: patient.patient_id,
        pagination: currentPage,
        startdate,
        enddate,
        type: currentTab
      };
      const results = await Axios.get(DISCHARGE_SUMMARY_LIST_API(PARAMS));
      if (results.data.data.dischargeSummaryList.records && results.data.data.dischargeSummaryList.records.length > 0) {
        await this.setState({
          loading: false,
          requestSent: true,
          allResults: results.data.data.dischargeSummaryList.records,
          pagination: results.data.data.dischargeSummaryList.pagination,
        });
      } else {
        await this.setState({
          loading: false
        });
      }
    }
  }

  handleRadioChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    this.setState({
      [name]: value
    }, () => {
      this.searchResults();
    })
  }

  async tabChanged(tab) {
    this.setState({
      currentTab: tab,
      currentPage: 1,
    }, () => this.resetForm(true))
  }

  navigateToPage(page) {
    this.setState({
      currentPage: page
    }, () => this.searchResults())
  }

  listing() {
    return this.state.allResults.length > 0 ?
      this.state.allResults.map((data, index) => (
        <div className="card bg-light mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="col-md-12">{data.dignosis_name}</div>
                  <div className="col-md-6 mb-3 small text-muted">{data.booking_date != null ? <Moment parse="YYYY-MM-DD" date={data.booking_date} format="D MMM, YYYY" /> : null} | {data.booking_time}</div>
                  {/* <div className="col-6">
                    {data.tests?.map((test, index) => (
                      test.name + index < test.length - 1 ? ", " : ""
                    ))}
                  </div> */}
                  <div className="col-md-6 text-right">
                    {data.diagnosis_type_image !== undefined ?
                    <img
                      src={data.diagnosis_type_image}
                      width={100}
                      MaxHeight={40}
                      alt="Report"
                      style={{ marginRight: 5 }}
                    />
                    : null}
                    {/* <a href onClick={() => this.setState({ rxPdfPath: data.patient_report_pdf, showRxModal: true })} className="text-success">View Report</a> */}
                    {
                      data.patient_report_image && data.patient_report_image != null && data.patient_report_image != ""
                    ?
                      <>
                        <span className="mr-4">
                          <a href={data.patient_report_image} target="_blank" className="text-success">View Image</a>
                        </span>
                        <span className="mr-4">
                          |
                        </span>
                      </>
                    :
                      null
                    }
                    <a href={data.patient_report_pdf} target="_blank" className="text-success">View Report</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )) :
      (!this.state.loading ? <div className="card bg-light mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              Reports not available for selected duration!
            </div>
          </div>
        </div>
      </div> : null);
  }
  uploadListing() {
    return this.state.allResults.length > 0
      ? this.state.allResults.map((data, index) => {
        return (
          <div className="card bg-light mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-md-6 "><strong>Patient Name:</strong> {data.name}</div>
                    <div className="col-md-6 text-right mb-3">{data.record_date != null ? <Moment parse="YYYY-MM-DD" date={data.record_date} format="D MMM, YYYY" /> : null} | {data.document_time}</div>
                    <div className="col-6">
                      {data.document_name}
                    </div>
                    <div className="col-md-6 text-right">

                      {/*  <img
                            src={data.cat_image}
                            width={100}
                            height={40}
                            alt="Report"
                            style={{marginRight:18,maxHeight:70,maxWidth:40}}
                        /> */}
                      {
                        data.documents?.map((file, index) => (
                          <a href className="show-rx-modal" key={index} onClick={e => this.handleModalPopup(e, 'viewDocumentModal', data.documents, index, 'img')}>
                            <i className={`mr-2 icon-${file.document_path.slice(file.document_path.length - 3) === 'pdf' ? 'pdf' : 'jpg'}`}></i>
                          </a>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
      :
      (!this.state.loading ? <div className="card bg-light mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              Uploads not available for selected duration!
            </div>
          </div>
        </div>
      </div> : null);
  }
  dischargeSummaryListing() {
    return this.state.allResults.length > 0
      ? this.state.allResults.map((data, index) => {
        return (
          <div className="card bg-light mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col-md-6 "><strong>Patient Name:</strong> {data.name}</div>
                    <div className="col-md-6 text-right mb-3">{data.record_date != null ? <Moment parse="YYYY-MM-DD" date={data.record_date} format="D MMM, YYYY" /> : null} | {data.document_time}</div>
                    <div className="col-6">
                      {data.document_name}
                    </div>
                    <div className="col-md-6 text-right">
                    <a href onClick={() => this.setState({ rxPdfPath: data.filepath, showRxModal: true })} className="text-success">View Report</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
      :
      (!this.state.loading ? <div className="card bg-light mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              Discharge summary not available for selected duration!
            </div>
          </div>
        </div>
      </div> : null);
  }

  handleModalPopup = async (e, popupName, data, index=0) => {
    //documentData
    await this.setState({
      documentData: data
    })
    if (e !== null) e.preventDefault();
    this.setState({
      [popupName]: !this.state[popupName]
    }, () => {
      const { viewDocumentModal } = this.state;
      if (viewDocumentModal && index) {
        this.carouselRef.current.goTo(index);
      }
    })
  }
  render() {
    const { patient, report_type } = this.state;
    const { match: { params } } = this.props;
    return (
      <>
        {patient != undefined && patient.patient_id != undefined ? (
          <AsideLeft
            patient_id={patient.patient_id}
            reference_id={patient.qms_token_id}
            isHomecare={params.reference_type == 2 ? true : false}
          />
        ) : null}
        <div className="content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-2">
              </div>
              <div className="col-8">
              {this.state.diagnosisReport?
                <ul className="nav nav-pills justify-content-center myhealthcare-tabs auto-width test-results-tabs" role="tablist">
                  <li className={`nav-item`}>
                    <a onClick={() => this.tabChanged('reports')} href="#reports"
                      className={"nav-link " + (this.state.currentTab === 'reports' ? 'active' : '')}
                      data-toggle="pill" role="tab" aria-controls="reports" aria-selected="true"> Patient's Reports</a>
                  </li>
                  <li className={`nav-item`}>
                    <a onClick={() => this.tabChanged('uploads')} href="#uploads"
                      className={"nav-link " + (this.state.currentTab === 'uploads' ? 'active' : '')}
                      data-toggle="pill" role="tab" aria-controls="uploads" aria-selected="false">Patient's Uploads</a>
                  </li>
                </ul>
                : null}
              </div>
              <div className="col">
                <div className="float-right">
                  <button className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <form onSubmit={(e) => { e.preventDefault(); this.searchResults(); }} className="emrfrm" id="reportSearchForm" autoComplete="off">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              {this.state.search_start_date != null ? <DateRangePicker
                                onCallback={this.dateFilter}
                                onEvent={this.onloadReset}
                                initialSettings={{
                                  startDate: this.state.search_start_date,
                                  endDate: this.state.search_end_date,
                                  ranges: this.state.preset_ranges,
                                }}
                              >
                                <button type="button" className="text-left form-control">{this.state.filterByValue}</button>
                              </DateRangePicker> : null}
                              <label className="control-label" htmlFor="search">Filter by duration</label>
                            </div>
                          </div>
                          <div className="col-auto">
                            <div onClick={(e) => { this.resetForm(); }} className="btn btn-primary reset cursor-pointer">RESET</div>
                          </div>
                          {this.state.currentTab == 'reports'
                            ?
                            <>
                              <div className="col-auto d-flex pt-1 pl-5">
                                <div className=" ml-3">
                                  <Radio.Group onChange={e => this.handleRadioChange(e)} name='report_type' value={report_type}>
                                    <Radio value={1} name="report" >LIS/RIS</Radio>
                                    <Radio value={2} name="report">Procedures</Radio>
                                    {
                                      EMR_APP_ENV == "mhea_rubyhall" ? 
                                      <Radio value={3} name="report">Rehab</Radio>
                                      :
                                      null
                                    }
                                  </Radio.Group>
                                </div>  
                              </div>
                            </>
                            :
                            null
                          }
                        </div>
                      </form>
                    </div>
                  </div>
                <div className="tab-content">
                  <div className="tab-pane show active">
                    {this.state.currentTab == 'reports' ? this.listing() : this.state.currentTab == 'uploads' ? this.uploadListing() : this.dischargeSummaryListing()}
                  </div>
                </div>
                {this.state.loading ?
                  <div className="col-lg-12 mb-5 text-center">{LOADER_RED}</div>
                  : null}

                {this.state.pagination != null && this.state.pagination.total > 0 ? <Pagination defaultCurrent={this.state.currentPage} total={this.state.pagination.total} onChange={this.navigateToPage} defaultPageSize={this.state.pagination.per_page} /> : null}
                <br />
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="View Reports"
          visible={this.state.showRxModal}
          footer={false}
          width={850}
          onCancel={() => { this.setState({ showRxModal: false, rxPdfPath: null }) }}
          style={{ top: 20 }}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              {this.state.rxPdfPath != null ? <iframe src={this.state.rxPdfPath} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                {LOADER_RED}
              </iframe> : null}
            </div>
          </div>
        </Modal>
        {/** Document Viewer */}
        <Modal
          title={this.state.documentData?.length === 1 ? "Document" : "Documents"}
          visible={this.state.viewDocumentModal}
          footer={false}
          width={850}
          onCancel={e => this.handleModalPopup(e, 'viewDocumentModal')}
          style={{ top: 20 }}
        >
          <div className="pdf_slider">
            {
              this.state.documentData?.length > 0 ? (
                <Carousel arrows dots={false} ref={this.carouselRef} >
                  {
                    this.state.documentData?.map((file, index) => (
                      file.type === 'img' ?
                        <figure key={index}><img className="mr-3" src={file.document_path} alt='uploaded Doc' /></figure>
                        :
                        <embed key={index} className="mr-3" src={file.document_path + "#toolbar=0&scrollbar=0&pagemode=none"} />
                    ))
                  }

                </Carousel>
              ) : null
            }
          </div>
        </Modal>

      </>
    )
  }
}

export default PatientDiagnosisReport;